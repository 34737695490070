import { Container, Typography, Box, TextField, MenuItem, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL; // Replace with your actual API URL

function CreateProduct() {

    const navigate = useNavigate();
    const [product, setProduct] = useState({
        name: '',
        description: '',
        price: '',
        measurementUnit: 'kg',
        CategoryId: ''
    });
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.get(`${apiUrl}/category/all`);
                setCategories(response.data);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };

        fetchCategories();
    }, []);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setProduct((prevProduct) => ({
            ...prevProduct,
            [name]: value,
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post(`${apiUrl}/product/new`, product);
            console.log(response.data);
            navigate('/product/all');
        } catch (error) {
            console.error('There was an error creating the product!', error);
        }
    };

    return (
        <Container>
            <Typography variant="h6" gutterBottom>
                Ajouter un nouveau produit
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 3 }}>
                <TextField
                    name="name"
                    label="Nom"
                    fullWidth
                    variant="outlined"
                    value={product.name}
                    onChange={handleChange}
                    required
                    sx={{ mb: 2 }}
                />
                <TextField
                    name="description"
                    label="Description"
                    fullWidth
                    variant="outlined"
                    value={product.description}
                    onChange={handleChange}
                    sx={{ mb: 2 }}
                />
                <TextField
                    name="measurementUnit"
                    label="Unité de mesure"
                    select
                    fullWidth
                    variant="outlined"
                    value={product.measurementUnit}
                    onChange={handleChange}
                    required
                    sx={{ mb: 2 }}
                >
                    <MenuItem value="kg">kg</MenuItem>
                    <MenuItem value="litre">litre</MenuItem>
                    <MenuItem value="unité">unité</MenuItem>
                </TextField>
                <TextField
                    name="price"
                    label="Prix par unité en Dirham"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={product.price}
                    onChange={handleChange}
                    required
                    sx={{ mb: 2 }}
                />
                <TextField
                    name="CategoryId"
                    label="Catégorie"
                    select
                    fullWidth
                    variant="outlined"
                    value={product.CategoryId}
                    onChange={handleChange}
                    required
                    sx={{ mb: 2 }}
                >
                    {categories.map((category) => (
                        <MenuItem key={category.id} value={category.id}>
                            {category.name}
                        </MenuItem>
                    ))}
                </TextField>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2 }}
                >
                    Ajouter
                </Button>
            </Box>
        </Container>
    );
}

export default CreateProduct;
