import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Stepper, Step, StepLabel, Button, Typography, Container, IconButton, TextField, Grid, MenuItem, Snackbar, Alert } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import '../../styles/RapportAnneeScolaire.scss';
import { useParams } from 'react-router-dom';

const apiUrl = process.env.REACT_APP_API_URL;

const ResultatsAnneeScolaire = () => {
  const { entityId } = useParams();
  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate();
  const [anneeScolaires, setAnneeScolaires] = useState([]);
  const [academiesRegionales, setAcademiesRegionales] = useState([]);
  const [entity, setEntity] = useState([]); 
  const [prefectures, setPrefectures] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [provinces, setProvinces] = useState([]);
  const [regions, setRegions] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [loggedUser, setLoggedUser] = useState(null); // State to store logged-in responsible's data
  const [formValues, setFormValues] = useState({
    nbPersonnel: '',
    nbBeneficiaireFeminin: '',
    nbBeneficiaireMasculin: '',
    nbAbandonScolaireFeminin: '',
    nbAbandonScolaireMasculin: '',
    nbFemininReussi: '',
    nbMasculinReussi: '',
    nbCandidatFemininBac: '',
    nbCandidatMasculinBac: '',
    nbReussiteBacFeminin: '',
    nbReussiteBacMasculin: '',
    AnneeScolaireId: '',
    EntityId: entityId,
    pourcentageReussiteFeminin: '',
    pourcentageReussiteMasculin: ''
  });
  const { t, i18n} = useTranslation();
  const switchLanguage = (language) => {
    i18n.changeLanguage(language);
  }

  const steps = [
    t('basicInfos'), // Translate the string using the t function
    t('beneficiaries'), // Translate the string using the t function
    t('beneficiariesAcademicInfos') // Translate the string using the t function
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch Annee Scolaires
        const anneeScolairesResponse = await axios.get(`${apiUrl}/anneescolaire/all`);
        setAnneeScolaires(anneeScolairesResponse.data);
        // Fetch Academies Regionales
        const academiesRegionalesResponse = await axios.get(`${apiUrl}/academieregionale/all`);
        setAcademiesRegionales(academiesRegionalesResponse.data);
        // Fetch region data
        const regionsResponse = await axios.get(`${apiUrl}/region/all`);
        setRegions(regionsResponse.data);
        // Fetch prefecture data
        const prefecturesResponse = await axios.get(`${apiUrl}/prefecture/all`);
        setPrefectures(prefecturesResponse.data);
        // Fetch province data
        const provincesResponse = await axios.get(`${apiUrl}/province/all`);
        setProvinces(provincesResponse.data);
        // Fetch selected entity data
        const entityResponse = await axios.get(`${apiUrl}/entity/${entityId}`);
        setEntity(entityResponse.data);
        // Fetch logged in user and entity based on session
        const sessionResponse = await axios.get(`${apiUrl}/session`);
        if (sessionResponse.data.success) {
          } else {
          navigate('/login');
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          navigate('/login');
        } else {
          console.error('Error:', error);
        }
      }
    };
  
    fetchData();
  }, [navigate]);
  

  // Function to get location name by ID
  const getLocationName = (locationId, locationType) => {
    if (locationType === 'Province') {
        const province = provinces.find(province => province.id === locationId);
        return province ? province.nom : '';
    } else if (locationType === 'Prefecture') {
        const prefecture = prefectures.find(prefecture => prefecture.id === locationId);
        return prefecture ? prefecture.nom : '';
    }
    return '';
  };
  // Function to get region name
  const getRegionName = (locationId, locationType) => {
    if (locationType === 'Province') {
        const province = provinces.find(province => province.id === locationId);
        if (province) {
            const regionId = province.RegionId;
            const region = regions.find(region => region.id === regionId);
            return region ? region.nom : '';
        }
    } else if (locationType === 'Prefecture') {
        const prefecture = prefectures.find(prefecture => prefecture.id === locationId);
        if (prefecture) {
            const regionId = prefecture.RegionId;
            const region = regions.find(region => region.id === regionId);
            return region ? region.nom : '';
        }
    }
    return '';
  };
  // Function to get Academie regionale name
  const getAcademieRegionalName = (locationId, locationType) => {
  let regionId = '';

  if (locationType === 'Province') {
    const province = provinces.find(province => province.id === locationId);
    if (province) {
      regionId = province.RegionId;
    }
  } else if (locationType === 'Prefecture') {
    const prefecture = prefectures.find(prefecture => prefecture.id === locationId);
    if (prefecture) {
      regionId = prefecture.RegionId;
    }
  }
  const academieRegionale = academiesRegionales.find(academie => academie.RegionId === regionId);
  return academieRegionale ? academieRegionale.nom : '';
  };

  const validateForm = () => {
    const requiredFieldsByStep = {
      0: ['nbPersonnel', 'AnneeScolaireId'],
      1: ['nbBeneficiaireFeminin', 'nbBeneficiaireMasculin', 'nbAbandonScolaireFeminin', 'nbAbandonScolaireMasculin'],
      2: ['nbFemininReussi', 'nbMasculinReussi', 'nbCandidatFemininBac', 'nbCandidatMasculinBac', 'nbReussiteBacFeminin', 'nbReussiteBacMasculin'],
    };

    const requiredFields = requiredFieldsByStep[activeStep];
    for (const field of requiredFields) {
      if (!formValues[field]) {
          // open the snackbar
          setSnackbarOpen(true);
        return false;
      }
    }
    return true;
  };

  const calculatePercentage = () => {
    // Calculate pourcentage reussite feminin
    if (formValues.nbBeneficiaireFeminin && formValues.nbFemininReussi) {
      const pourcentageReussiteFeminin = (formValues.nbFemininReussi / formValues.nbBeneficiaireFeminin) * 100;
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        pourcentageReussiteFeminin: pourcentageReussiteFeminin.toFixed(2)// round to 2 decimal places
      }));
    }

    // Calculate pourcentage reussite masculin
    if (formValues.nbBeneficiaireMasculin && formValues.nbMasculinReussi) {
      const pourcentageReussiteMasculin = (formValues.nbMasculinReussi / formValues.nbBeneficiaireMasculin) * 100;
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        pourcentageReussiteMasculin: pourcentageReussiteMasculin.toFixed(2)// round to 2 decimal places
      }));
    }
  };

  const handleNext = () => {
    if (!validateForm()) {
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const totalSteps = () => {
    return steps.length;
  };

  const handleHomeClick = () => {
    navigate('/accueilresponsable');
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Update formValues using callback form of setFormValues
    setFormValues(prevState => ({
      ...prevState,
      [name]: value
    }));

    // Recalculate percentage success when relevant input changes
    calculatePercentage();
  };

  const handleSubmit = async () => {
    if (activeStep === totalSteps() - 1) {
      try {
        const response = await axios.post(`${apiUrl}/resultatsanneescolaire/new`, formValues);
        console.log('Data submitted successfully:', response.data);
        // Fetch user data again after form submission
        const sessionResponse = await axios.get(`${apiUrl}/session`);
        if (sessionResponse.data.success) {
          setLoggedUser(sessionResponse.data.user);
        }
        setOpenSnackbar(true);
      } catch (error) {
        console.error('Error submitting data:', error);
      }
    }
    handleNext();
  }

  return (
    <Container>
      <div className='top-header-rapports'>
        <div className='homeButton'>
          <IconButton onClick={handleHomeClick}>
            <HomeIcon />
          </IconButton>
        </div>
        <div className='language-selector'>
            <span className="language-option" onClick={() => switchLanguage('fr')}>Français</span> | 
            <span className="language-option" onClick={() => switchLanguage('ar')}>العربية</span>
        </div>
      </div>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography> {t('finished')} </Typography>
          </div>
        ) : (
          <div className='form'>
            {activeStep === 0 && (
              <form>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                        name="nom"
                        label= {t('name')}
                        fullWidth
                        value={entity.nom || ''}
                        disabled
                    />                  
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                        name="telephone"
                        label= {t('phoneNumber')}
                        fullWidth
                        value={'0'+ entity.telephone || ''}
                        disabled
                    />                  
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                        name="telephoneFixe"
                        label={t('Numero de telephone fixe')}
                        fullWidth
                        value={entity.telephoneFixe || ''}
                        disabled
                    />                  
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                        name="region"
                        label={t('region')}
                        fullWidth
                        value={getRegionName(entity.locationId, entity.locationType) || ''}
                        disabled
                    />                  
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                        name="academieRegional"
                        label={t('regionalAcademy')}
                        fullWidth
                        value={getAcademieRegionalName(entity.locationId, entity.locationType) || ''}
                        disabled
                    />                  
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                        name="provinceOrPrefecture"
                        label={t('prefectureOrProvince')}
                        fullWidth
                        value={getLocationName(entity.locationId, entity.locationType) || ''} 
                        disabled
                    />                  
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                        name="adresse"
                        label= {t('address')}
                        fullWidth
                        value={entity.adresse || ''}
                        disabled
                    />                  
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                        name="nbPersonnel"
                        label= {t('numberOfPersonnel')}
                        type='number'
                        fullWidth
                        value={formValues.nbPersonnel}
                        onChange={handleInputChange}
                        required
                    />                  
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      select
                        name="AnneeScolaireId"
                        label= {t('schoolYear')}
                        type='number'
                        fullWidth
                        value={formValues.AnneeScolaireId}
                        onChange={handleInputChange}
                        required
                    >
                    {anneeScolaires.map(anneeScolaire => (
                      <MenuItem key = {anneeScolaire.id} value = {anneeScolaire.id}>
                        {anneeScolaire.nom}
                      </MenuItem>
                    ))}
                    </TextField>                  
                  </Grid>
                </Grid>
                
              </form>
            )}
            {activeStep === 1 && (
              <form>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="nbBeneficiaireFeminin"
                      label={t("numberOfFemaleBeneficiaries")}
                      type="number"
                      fullWidth
                      value={formValues.nbBeneficiaireFeminin}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="nbBeneficiaireMasculin"
                      label={t("numberOfMaleBeneficiaries")}
                      type="number"
                      fullWidth
                      value={formValues.nbBeneficiaireMasculin}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="nbAbandonScolaireFeminin"
                      label={t("NumberOfFemaleSchoolDropout")}
                      type="number"
                      fullWidth
                      value={formValues.nbAbandonScolaireFeminin}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="nbAbandonScolaireMasculin"
                      label={t("Nombre d'abandon scolaire garçon")}
                      type="number"
                      fullWidth
                      value={formValues.nbAbandonScolaireMasculin}
                      onChange={handleInputChange}
                    />
                  </Grid>
                </Grid>
              </form>
            )}
            {activeStep === 2 && (
              <form>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="nbFemininReussi"
                      label={t("Nombre de réussite feminin")}
                      type="number"
                      fullWidth
                      value={formValues.nbFemininReussi}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="nbMasculinReussi"
                      label={t("Nombre de réussite masculin")}
                      type="number"
                      fullWidth
                      value={formValues.nbMasculinReussi}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="pourcentageReussiteFeminin"
                      label={t("Pourcentage de réussite feminin")}
                      type="number"
                      fullWidth
                      value={formValues.pourcentageReussiteFeminin}
                      onChange={handleInputChange}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="pourcentageReussiteMasculin"
                      label={t("Pourcentage de réussite masculin")}
                      type="number"
                      fullWidth
                      value={formValues.pourcentageReussiteMasculin}
                      onChange={handleInputChange}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="nbCandidatFemininBac"
                      label={t("Nombre candidat baccalaureat feminin")}
                      type="number"
                      fullWidth
                      value={formValues.nbCandidatFemininBac}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="nbCandidatMasculinBac"
                      label={t("Nombre candidat baccalaureat masculin")}
                      type="number"
                      fullWidth
                      value={formValues.nbCandidatMasculinBac}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="nbReussiteBacFeminin"
                      label={t("Nombre reussite baccalaureat feminin")}
                      type="number"
                      fullWidth
                      value={formValues.nbReussiteBacFeminin}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="nbReussiteBacMasculin"
                      label={t("Nombre reussite baccalaureat feminin")}
                      type="number"
                      fullWidth
                      value={formValues.nbReussiteBacMasculin}
                      onChange={handleInputChange}
                    />
                  </Grid>
                </Grid>
              </form>
            )}
            <div className='navButtons'>
              <Button disabled={activeStep === 0} onClick={handleBack}>
                {t('retour')}
              </Button>
              <Button variant="contained" onClick={handleSubmit}>
                {activeStep === steps.length - 1 ? t('terminer') : t('suivant')}
              </Button>
            </div>
          </div>
        )}
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity='error' variant='filled'>
        {t('please fill out all the fields !')}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={() => {
          setOpenSnackbar(false);
          navigate('/accueilresponsable');
        }}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity="success" variant='filled'>
            {t('School year report submitted successfully')}
        </Alert>
      </Snackbar>
    </Container>
    
  );
};

export default ResultatsAnneeScolaire;