import React, { useState, useEffect } from 'react';
import { Container, Stepper, Step, StepLabel, Button, Typography, Grid, TextField, MenuItem, FormControl, InputLabel, Select } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ConsommationProduct from '../../components/formComponents/formComponentsRapportTrimestriel/ConsommationProduct';
import axios from 'axios';
import '../../styles/RapportTrimestriel.scss';

const apiUrl = process.env.REACT_APP_API_URL;

const EditRapportTrimestrielByEntity = () => {
  const { id } = useParams();
  const [activeStep, setActiveStep] = useState(0);
  const [anneeScolaires, setAnneeScolaires] = useState([]);
  const [trimestres, setTrimestres] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [consommationProducts, setConsommationProducts] = useState([]);
  const [formValues, setFormValues] = useState({
    nbBeneficiaireFeminin: '',
    nbBeneficiaireMasculin: '',
    nbExemptionCotisationMensuel: '',
    nbBeneficiaireSemestreFeminin: '',
    nbBeneficiaireSemestreMasculin: '',
    nbFemininQuitterCentre: '',
    nbMasculinQuitterCentre: '',
    raisonQuitterCentre: '',
    remarqueIndemniteQuitterCentre: '',
    nbTasaroubFeminin: '',
    nbTasaroubMasculin: '',
    raisonTasaroub: '',
    remarqueTasaroub: '',
    nbHadrMadrassiFeminin: '',
    nbHadrMadrassiMasculin: '',
    raisonHadrMadrassi: '',
    remarqueHadrMadrassi: '',
    coteOrganisationnelCentre: '',
    coteAlimentaireCentre: '',
    typeCasEnregistreSante: '',
    nbCasEnregistreSante: '',
    typeTraitementCasEnregistreSante: '',
    dispensaire: '',
    TrimestreId: '',
    anneeScolaireId: '',
  });
  
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const steps = [t('basicInfos'), t('beneficiaries'), t('beneficiariesAcademicInfos'), t('Health'), t('Consumption of Food/Hygiene Products')];


  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch existing rapport trimestriel data
        const rapportResponse = await axios.get(`${apiUrl}/rapporttrimestriel/${id}`);
        setFormValues(rapportResponse.data);
        const response = await axios.get(`${apiUrl}/product/all`);
        setProducts(response.data);
        // Fetch consommationProduct related to the rapport trimestriel
        const consommationProductResponse = await axios.get(`${apiUrl}/consommationproduct/byrapporttrimestriel/${id}`);
        setConsommationProducts(consommationProductResponse.data);
        console.log(consommationProducts);
        // Fetch Annee Scolaires
        const anneeScolairesResponse = await axios.get(`${apiUrl}/anneescolaire/all`);
        setAnneeScolaires(anneeScolairesResponse.data);
        // Fetch Trimestres
        const trimestresResponse = await axios.get(`${apiUrl}/trimestre/all`);
        setTrimestres(trimestresResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
      }
    };

    fetchData();
  }, [id, navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues(prevFormValues => ({
      ...prevFormValues,
      [name]: value
    }));
  };

  const handleSubmit = async () => {
    if (activeStep === 3) {
        try {
            // Submit the form only on the last step
            await axios.put(`${apiUrl}/rapporttrimestriel/${id}`, formValues);
            console.log('Rapport Trimestriel updated successfully:');
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    }

    if (activeStep === 4) {
        try {
            for (const product of consommationProducts) {
                console.log('Submitting product:', product); // Log each product

                if (product.id) {
                    // Update existing product
                    await axios.put(`${apiUrl}/consommationproduct/edit/${product.id}`, {
                        rapportTrimestrielId: id,
                        productId: product.ProductId,
                        quantityReceived: product.quantityReceived,
                        totalRecu: product.totalRecu,
                        totalParticipationBanqueAlimentaire: product.totalParticipationBanqueAlimentaire
                    });
                } else {
                    // Handle creation of new products
                    const response = await axios.post(`${apiUrl}/consommationproduct/new`, {
                        RapportTrimestrielId: id,
                        ProductId: product.ProductId,
                        quantityReceived: product.quantityReceived,
                        totalRecu: product.totalRecu,
                        totalParticipationBanqueAlimentaire: product.totalParticipationBanqueAlimentaire
                    });

                    console.log('New product response:', response.data); // Log the response

                    // Update the state with the new product ID and rapportTrimestrielId
                    if (response.data && response.data.id) {
                        const newProduct = response.data;
                        setConsommationProducts(prevProducts =>
                            prevProducts.map(p =>
                                p === product ? { ...p, id: newProduct.id, rapportTrimestrielId: newProduct.rapportTrimestrielId } : p
                            )
                        );
                    }
                }
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    }

    handleNext(); // Proceed to the next step if it's not the last one
};


  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const totalSteps = () => {
    return steps.length;
  };

  const handleNext = () => {
    if (!validateForm()) {
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const validateForm = () => {
    // Define the required fields for each step of the form
    const requiredFieldsByStep = {
        0: [], // Required fields for step 0
        1: ['nbBeneficiaireFeminin', 'nbBeneficiaireMasculin', 'nbExemptionCotisationMensuel', 'nbBeneficiaireSemestreFeminin', 'nbBeneficiaireSemestreMasculin'], // Required fields for step 1
        2: ['nbFemininQuitterCentre', 'nbMasculinQuitterCentre', 'raisonQuitterCentre', 'remarqueIndemniteQuitterCentre', 'nbTasaroubFeminin', 'nbTasaroubMasculin', 'raisonTasaroub', 'remarqueTasaroub', 'nbHadrMadrassiFeminin', 'nbHadrMadrassiMasculin', 'raisonHadrMadrassi', 'remarqueHadrMadrassi', 'coteOrganisationnelCentre', 'coteAlimentaireCentre'], // Required fields for step 2
        3: ['typeCasEnregistreSante', 'nbCasEnregistreSante', 'typeTraitementCasEnregistreSante', 'dispensaire'], // Required fields for step 3
        // Step 4 has no required fields in this case
    };

    // If it's the last step, skip validation
    if (activeStep === 4) {
      return true;
    }

    // Get the list of required fields for the current step
    const requiredFields = requiredFieldsByStep[activeStep];
    
    // Check if all required fields are filled in
    for (const field of requiredFields) {
        if (!formValues[field]) {
            // If any required field is missing, open the snackbar and return false
            setSnackbarOpen(true);
            return false;
        }
    }
    
    // Return true if all required fields are filled in
    return true;
  };

  // Define the required functions if not already defined
  const handleProductChange = (index, field, value) => {
    // Update the specific product's data
    const updatedProducts = consommationProducts.map((product, idx) => 
      idx === index ? { ...product, [field]: value } : product
    );
    
    // Log the updated product data
    console.log(`Product at index ${index} updated:`, updatedProducts[index]);
    
    // Update the state
    setConsommationProducts(updatedProducts);
  };
  

  const handleAddProduct = () => {
    const newProduct = {
      ProductId: '',
      quantityReceived: '',
      totalRecu: '',
      totalParticipationBanqueAlimentaire: '',
      rapportTrimestrielId: id // Ensure rapportTrimestrielId is included
    };
    
    // Log the new product data before adding
    console.log('Adding new product:', newProduct);
    
    setConsommationProducts([
      ...consommationProducts,
      newProduct
    ]);
  };
  

  {activeStep === 0 && (
    <form>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            select
            name="anneeScolaireId"
            label="Année scolaire"
            type="number"
            fullWidth
            value={formValues.anneeScolaireId}
            onChange={handleInputChange}
          >
            {anneeScolaires.map(anneeScolaire => (
              <MenuItem key={anneeScolaire.id} value={anneeScolaire.id}>
                {anneeScolaire.nom}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            select
            name="TrimestreId"
            label="Quarter"
            fullWidth
            value={formValues.TrimestreId}
            onChange={handleInputChange}
          >
            {trimestres.map(trimestre => (
              <MenuItem key={trimestre.id} value={trimestre.id}>
                {trimestre.nom}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
    </form>
  )}  
  return (
    <Container>
      {/* ... Similar structure to RapportTrimestriel.js ... */}
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography>Términé</Typography>
          </div>
        ) : (
          <div className='form'>
            {activeStep === 0 && (
              <form>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      select
                        name="anneeScolaireId"
                        label="Année scolaire"
                        type='number'
                        fullWidth
                        value={formValues.anneeScolaireId}
                        onChange={handleInputChange}
                    >
                    {anneeScolaires.map(anneeScolaire => (
                      <MenuItem key = {anneeScolaire.id} value = {anneeScolaire.id}>
                        {anneeScolaire.nom}
                      </MenuItem>
                    ))}
                    </TextField>                  
                  </Grid>
                  {formValues.anneeScolaireId && (
                    <Grid item xs={12} sm={6}>
                    <TextField
                      select
                      name="TrimestreId"
                      label={t("Quarter")}
                      fullWidth
                      value={formValues.TrimestreId}
                      onChange={handleInputChange}
                    >
                      {trimestres.filter(trimestre => trimestre.AnneeScolaireId === formValues.anneeScolaireId).map(trimestre => (
                        <MenuItem key={trimestre.id} value={trimestre.id}>
                          {trimestre.nom}
                        </MenuItem>
                      ))}
                      </TextField>
                  </Grid>
                  )}
               </Grid>
              </form>
            )}
            {activeStep === 1 && (
              <form>
                <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextField
                      name="nbBeneficiaireFeminin"
                      label={"Nombre de bénéficiaire féminin"}
                      type='number'
                      fullWidth
                      value={formValues.nbBeneficiaireFeminin}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="nbBeneficiaireMasculin"
                      label={t("Nombre de bénéficiaire masculin")}
                      type='number'
                      fullWidth
                      value={formValues.nbBeneficiaireMasculin}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="nbExemptionCotisationMensuel"
                      label={"Nombre d'exemption cotisation mensuel"}
                      type='number'
                      fullWidth
                      value={formValues.nbExemptionCotisationMensuel}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="nbBeneficiaireSemestreFeminin"
                      label={"Nombre de beneficiaire durant ce semestre Feminin"}
                      type='number'
                      fullWidth
                      value={formValues.nbBeneficiaireSemestreFeminin}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="nbBeneficiaireSemestreMasculin"
                      label={"Nombre de beneficiaire durant ce semestre Masculin"}
                      type='number'
                      fullWidth
                      value={formValues.nbBeneficiaireSemestreMasculin}
                      onChange={handleInputChange}
                    />
                  </Grid>
                </Grid>
              </form>
            )}
            {activeStep === 2 && (
              <form>
                <Grid container spacing={2}>
                 <Grid item xs={12} sm={3}>
                    <TextField
                      name="nbFemininQuitterCentre"
                      label={"Nombre de cas de depart féminin"}
                      type="number"
                      fullWidth
                      value={formValues.nbFemininQuitterCentre}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="nbMasculinQuitterCentre"
                      label={"Nombre de cas de depart masculin"}
                      type="number"
                      fullWidth
                      value={formValues.nbMasculinQuitterCentre}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="raisonQuitterCentre"
                      label={t("Raison de depart")}
                      type="text"
                      fullWidth
                      value={formValues.raisonQuitterCentre}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="remarqueIndemniteQuitterCentre"
                      label={t("Remarque/Indemnité")}
                      type="text"
                      fullWidth
                      value={formValues.remarqueIndemniteQuitterCentre}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="nbTasaroubFeminin"
                      label={t("Nombre de cas Tasaroub dirassi féminin")}
                      type="number"
                      fullWidth
                      value={formValues.nbTasaroubFeminin}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="nbTasaroubMasculin"
                      label={t("Nombre de cas Tasaroub dirassi masculin")}
                      type="number"
                      fullWidth
                      value={formValues.nbTasaroubMasculin}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="raisonTasaroub"
                      label={t("Raison Tasaroub")}
                      type="text"
                      fullWidth
                      value={formValues.raisonTasaroub}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="remarqueTasaroub"
                      label={t("Remarque/Indemnité")}
                      type="text"
                      fullWidth
                      value={formValues.remarqueTasaroub}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="nbHadrMadrassiFeminin"
                      label={t("Nombre de cas hadr madrassi féminin")}
                      type="number"
                      fullWidth
                      value={formValues.nbHadrMadrassiFeminin}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="nbHadrMadrassiMasculin"
                      label={t("Nombre de cas hadr madrassi masculin")}
                      type="number"
                      fullWidth
                      value={formValues.nbHadrMadrassiMasculin}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="raisonHadrMadrassi"
                      label={t("Raison")}
                      type="text"
                      fullWidth
                      value={formValues.raisonHadrMadrassi}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="remarqueHadrMadrassi"
                      label={t("Remarque/Indemnité")}
                      type="text"
                      fullWidth
                      value={formValues.remarqueHadrMadrassi}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="coteOrganisationnelCentre"
                      label={t("Cote organisationnel de l'entité")}
                      type="text"
                      fullWidth
                      value={formValues.coteOrganisationnelCentre}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="coteAlimentaireCentre"
                      label={t("Cote Alimentation de l'entité: Participation de la Banque Alimentaire et autres partenaires")}
                      type="text"
                      fullWidth
                      value={formValues.coteAlimentaireCentre}
                      onChange={handleInputChange}
                    />
                  </Grid>
                </Grid>
              </form>
            )}     
            {activeStep === 3 && (
              <form>
                <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                    <TextField
                      name="typeCasEnregistreSante"
                      label={t("Type de cas enregristré(s)")}
                      type="text"
                      fullWidth
                      value={formValues.typeCasEnregistreSante}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="nbCasEnregistreSante"
                      label={t("Nombre de cas enregistré(s)")}
                      type="number"
                      fullWidth
                      value={formValues.nbCasEnregistreSante}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="typeTraitementCasEnregistreSante"
                      label={t("Type de traitement")}
                      type="text"
                      fullWidth
                      value={formValues.typeTraitementCasEnregistreSante}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="dispensaire"
                      label={t("Dispensaire a proximité")}
                      type="text"
                      fullWidth
                      value={formValues.dispensaire}
                      onChange={handleInputChange}
                    />
                  </Grid>
                </Grid>
              </form>
            )}
            {activeStep === 4 && (
              <form>
                <Grid container spacing={2}>
                  {consommationProducts.map((product, index) => (
                    <Grid item xs={12} key={index}>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} sm={3}>
                          <FormControl fullWidth>
                            <InputLabel id={`product-label-${index}`}>Product</InputLabel>
                            <Select
                              labelId={`product-label-${index}`}
                              value={product.ProductId || ''}
                              onChange={(e) => handleProductChange(index, 'ProductId', e.target.value)}
                              fullWidth
                            >
                              <MenuItem value="">
                                <em>Select a product</em>
                              </MenuItem>
                              {products.map((prod) => (
                                <MenuItem key={prod.id} value={prod.id}>
                                  {prod.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <TextField
                            label="Quantity Received"
                            type="number"
                            value={product.quantityReceived}
                            onChange={(e) => handleProductChange(index, 'quantityReceived', e.target.value)}
                            fullWidth
                            required
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <TextField
                            label="Total Received"
                            type="number"
                            value={product.totalRecu}
                            onChange={(e) => handleProductChange(index, 'totalRecu', e.target.value)}
                            fullWidth
                            required
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <TextField
                            label="Total Participation Banque Alimentaire"
                            type="number"
                            value={product.totalParticipationBanqueAlimentaire}
                            onChange={(e) => handleProductChange(index, 'totalParticipationBanqueAlimentaire', e.target.value)}
                            fullWidth
                            required
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddProduct}
                  sx={{ mt: 2 }}
                >
                  Add Product
                </Button>
              </form>
            )}


            <div className='navButtons'>
              <Button disabled={activeStep === 0 || activeStep === totalSteps() - 1} onClick={handleBack}>
                {'retour'}
              </Button>
              <Button variant="contained" onClick={handleSubmit}>
                {activeStep === steps.length - 1 ? 'terminer' : 'suivant'}
              </Button>
            </div>

          </div>
        )}
      </div>
      
    </Container>
  );
};

export default EditRapportTrimestrielByEntity;