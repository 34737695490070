import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, IconButton, Button, Typography } from '@mui/material';
import axios from 'axios'; // Import axios library for making HTTP requests
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';

const apiUrl = process.env.REACT_APP_API_URL;

const DarTalibas = () => {
    const [data, setData] = useState([]); // State to hold the fetched data
    const [searchTerm, setSearchTerm] = useState('');
    const [regions, setRegions] = useState([]); // State to hold the fetched region data
    const [responsables, setResponsables] = useState([]); // State to hold the fetched responsable data
    const [provinces, setProvinces] = useState([]); // State to hold the fetched province data
    const [prefectures, setPrefectures] = useState([]); // State to hold the fetched prefecture data
    const [locations, setLocations] = useState({}); // State to hold location data


    // Fetching the Data
    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch DarTaliba data
                const darTalibaResponse = await axios.get(`${apiUrl}/entity/dartaliba/all`);
                setData(darTalibaResponse.data); // Set the fetched data to the state

                // Fetch region data
                const regionsResponse = await axios.get(`${apiUrl}/region/all`);
                setRegions(regionsResponse.data); // Set region data to the state

                // Fetch Responsable data
                const responsablesResponse = await axios.get(`${apiUrl}/user/all`);
                setResponsables(responsablesResponse.data); // Set responsable data to the state

                // Fetch prefecture data
                const prefecturesResponse = await axios.get(`${apiUrl}/prefecture/all`);
                setPrefectures(prefecturesResponse.data); // Set prefecture data to the state

                // Fetch province data
                const provincesResponse = await axios.get(`${apiUrl}/province/all`);
                setProvinces(provincesResponse.data); // Set province data to the state
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    // Function to get location name by ID
    const getLocationName = (locationId, locationType) => {
        if (locationType === 'Province') {
            const province = provinces.find(province => province.id === locationId);
            return province ? province.nom : '';
        } else if (locationType === 'Prefecture') {
            const prefecture = prefectures.find(prefecture => prefecture.id === locationId);
            return prefecture ? prefecture.nom : '';
        }
        return '';
    };

    // Function to get region name
    const getRegionName = (locationId, locationType) => {
        if (locationType === 'Province') {
            const province = provinces.find(province => province.id === locationId);
            if (province) {
                const regionId = province.RegionId;
                const region = regions.find(region => region.id === regionId);
                return region ? region.nom : '';
            }
        } else if (locationType === 'Prefecture') {
            const prefecture = prefectures.find(prefecture => prefecture.id === locationId);
            if (prefecture) {
                const regionId = prefecture.RegionId;
                const region = regions.find(region => region.id === regionId);
                return region ? region.nom : '';
            }
        }
        return '';
        };

    // Function to fetch Responsable name by Id
    const getResponsableName = (responsableId) => {
        const responsable = responsables.find(user => user.id === responsableId);
        return responsable ? `${responsable.nom} ${responsable.prenom}` : '';
    };

    // Filter data based on search term
    const filteredData = data.filter(item =>
        item.nom.toLowerCase().includes(searchTerm.toLowerCase())
    );
    
    return (
        <div>
            <Typography variant='h6'>Liste des Dar Talibat</Typography>
            <TextField
                label="Dar Taliba ..."
                variant="outlined"
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{ marginBottom: '20px', marginTop: '20px' }}
            />
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Dar Talib-a</TableCell>
                            <TableCell>Responsable</TableCell>
                            <TableCell>Téléphone</TableCell>
                            <TableCell>Téléphone Fixe</TableCell>
                            <TableCell>Nombre de Garçons</TableCell>
                            <TableCell>Nombre de Filles</TableCell>
                            <TableCell>Préfecture/Province</TableCell>
                            <TableCell>Région</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredData.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell>{row.nom}</TableCell>
                                <TableCell>{getResponsableName(row.UserId)}</TableCell>
                                <TableCell>{row.telephone}</TableCell>
                                <TableCell>{row.telephoneFixe}</TableCell>
                                <TableCell>{row.totalBoys}</TableCell>
                                <TableCell>{row.totalGirls}</TableCell>
                                <TableCell>{getLocationName(row.locationId, row.locationType)}</TableCell>
                                <TableCell>{getRegionName(row.locationId, row.locationType)}</TableCell>
                                <TableCell><IconButton component={Link} to={`/entity/${row.id}`}><RemoveRedEyeOutlinedIcon/></IconButton></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default DarTalibas;
