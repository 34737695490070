import { TableCell, TableContainer, TableHead, Paper, Table, TableRow, Container, Typography, Button, TableBody } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

function Products() {
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axios.get(`${apiUrl}/product/all`);
                setProducts(response.data);
            } catch (error) {
                console.error('Error fetching the products:', error);
            }
        };

        const fetchCategories = async () => {
            try {
                const response = await axios.get(`${apiUrl}/category/all`);
                setCategories(response.data);
            } catch (error) {
                console.error('Error fetching the categories:', error);
            }
        };

        fetchProducts();
        fetchCategories();
    }, []);

    // Create a map of categoryId to categoryName for quick lookup
    const categoryMap = categories.reduce((map, category) => {
        map[category.id] = category.name;
        return map;
    }, {});

    return (
        <Container>
            <Typography variant="h6" gutterBottom>
                Liste des produits
            </Typography>
            <Button
                component={Link}
                to="/product/new"
                variant="contained"
                color="primary"
                sx={{ mr: 2, mb: 2 }}
            >
                Ajouter un nouveau produit
            </Button>
            <Button
                component={Link}
                to="/category/all"
                variant="contained"
                color="primary"
                sx={{ mr: 2, mb: 2 }}
            >
                Liste des catégories
            </Button>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Nom</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Unité de mesure</TableCell>
                            <TableCell>Prix</TableCell>
                            <TableCell>Catégorie</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {products.map((product) => (
                            <TableRow key={product.id}>
                                <TableCell>{product.name}</TableCell>
                                <TableCell>{product.description}</TableCell>
                                <TableCell>{product.measurementUnit}</TableCell>
                                <TableCell>{product.price} Dhs</TableCell>
                                <TableCell>{categoryMap[product.CategoryId] || 'No Category'}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
}

export default Products;
