import { Container, Typography, Box, TextField, Button } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL; // Replace with your actual API URL

function CreateCategory() {
    const navigate = useNavigate();
    const [category, setCategory] = useState({
        name: '',
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setCategory((prevCategory) => ({
            ...prevCategory,
            [name]: value,
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post(`${apiUrl}/category/new`, category);
            console.log(response.data);
            navigate('/category/all');
        } catch (error) {
            console.error('There was an error creating the category!', error);
        }
    };

    return (
        <Container>
            <Typography variant="h6" gutterBottom>
                Créer une nouvelle catégorie
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 3 }}>
                <TextField
                    name="name"
                    label="Nom de la catégorie"
                    fullWidth
                    variant="outlined"
                    value={category.name}
                    onChange={handleChange}
                    required
                    sx={{ mb: 2 }}
                />
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2 }}
                >
                    Créer
                </Button>
            </Box>
        </Container>
    );
}

export default CreateCategory;
