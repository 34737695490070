import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { Table, TableHead, TextField, TableBody, TableRow, TableCell, Paper, TableContainer, Snackbar, Alert, Typography } from '@mui/material';

const apiUrl = process.env.REACT_APP_API_URL;

const AfficherResultatsAnneeScolaires = () => {
    const [rapportsAnneeScolaires, setRapportsAnneeScolaires] = useState([]);
    const [entities, setEntities] = useState({});
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [anneeScolaires, setAnneeScolaires] = useState({});
    const [users, setUsers] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [rapportsResponse, entitiesResponse, anneeScolairesResponse, usersResponse] = await Promise.all([
                    axios.get(`${apiUrl}/resultatsanneescolaire/all`),
                    axios.get(`${apiUrl}/entity/all`),
                    axios.get(`${apiUrl}/anneescolaire/all`),
                    axios.get(`${apiUrl}/user/all`),
                ]);

                const rapportData = rapportsResponse.data;
                const entitiesData = entitiesResponse.data.reduce((acc, entity) => {
                    acc[entity.id] = entity;
                    return acc;
                }, {});
                const anneeScolairesData = anneeScolairesResponse.data.reduce((acc, year) => {
                    acc[year.id] = year;
                    return acc;
                }, {});

                const usersData = usersResponse.data.reduce((acc, user) => {
                    acc[user.id] = user;
                    return acc;
                }, {});
                

                setRapportsAnneeScolaires(rapportData);
                setEntities(entitiesData);
                setAnneeScolaires(anneeScolairesData);
                setUsers(usersData); // Update the state with user data
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const handleDownloadReport = async (id, entityId, anneeScolaireId) => {
        try {
            console.log('Downloading report...');
            const response = await axios.get(`${apiUrl}/downloadreport/anneescolaire/${id}`, {
                responseType: 'blob', // Set responseType to blob for downloading binary data
            });
            console.log('Report downloaded successfully:', response);

            // Fetch the entity name based on entityId
            const entityName = entities[entityId]?.nom || 'Unknown';
            // Fetch the school year name based on anneeScolaireId
            const anneeScolaireName = anneeScolaires[anneeScolaireId]?.nom || 'Unknown';
            // Create a Blob object from the response data
            const blob = new Blob([response.data], { type: response.headers['content-type'] });
    
            // Create a URL for the Blob object
            const url = window.URL.createObjectURL(blob);
    
            // Create a link element to initiate the download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `resultatsAnneeScolaire_${entityName}_${anneeScolaireName}.docx`);
            document.body.appendChild(link);
    
            // Trigger the download
            link.click();
    
            // Cleanup
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading report:', error);
        }
    };

    const handleDelete = async (id) => {
        const confirmDelete = window.confirm('Êtes-vous sûr·e de vouloir supprimer ce rapport ?');
        if (confirmDelete) {
            try {
                await axios.delete(`${apiUrl}/rapportanneescolaire/${id}`);
                setRapportsAnneeScolaires((prevReports) => prevReports.filter(report => report.id !== id));
                setSnackbarOpen(true);
            } catch (error) {
                console.error('Error deleting report:', error);
            }
        }
    }
    
    // Filter data based on the search term
    const filteredData = rapportsAnneeScolaires.filter((row) => {
        const entityName = entities[row.EntityId]?.nom?.toLowerCase() || '';
        const anneeScolaireName = anneeScolaires[row.AnneeScolaireId]?.nom?.toLowerCase() || '';
        const userId = entities[row.EntityId]?.UserId;
        const responsableName = `${users[userId]?.nom?.toLowerCase()} ${users[userId]?.prenom?.toLowerCase()}`.trim();

        // Check if the search term matches any of the filtered properties
        return entityName.includes(searchTerm.toLowerCase()) ||
            anneeScolaireName.includes(searchTerm.toLowerCase()) ||
            responsableName.includes(searchTerm.toLowerCase());
    });

    return (
        <div>
            <Typography variant='h6'>Liste des resultats des années scolaire</Typography>
            <TextField
                label="Chercher ..."
                variant="outlined"
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{ marginBottom: '20px', marginTop: '20px' }}
            />
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Entité</TableCell>
                            <TableCell>Année scolaire</TableCell>
                            <TableCell>Responsable</TableCell>
                            <TableCell>Télécharger</TableCell>
                            <TableCell>Supprimer</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredData.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell>{entities[row.EntityId]?.nom}</TableCell>
                                <TableCell>{anneeScolaires[row.AnneeScolaireId]?.nom}</TableCell>
                                <TableCell>{users[entities[row.EntityId]?.UserId]?.nom} {users[entities[row.EntityId]?.UserId]?.prenom}</TableCell>
                                <TableCell>
                                    <IconButton onClick={() => handleDownloadReport(row.id, row.EntityId, row.AnneeScolaireId)}><CloudDownloadIcon /></IconButton>
                                </TableCell>
                                <TableCell>
                                    <IconButton onClick={() => handleDelete(row.id)} ><DeleteIcon /></IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={() => setSnackbarOpen(false)}>
                <Alert onClose={() => setSnackbarOpen(false)} severity='success' variant='filled' sx={{ width: '100%' }}>
                    Rapport supprimé avec succès.
                </Alert>
            </Snackbar>
        </div>
    );
};

export default AfficherResultatsAnneeScolaires;
