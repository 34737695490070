import React, { useEffect, useState } from "react";
import { Box, Button, Grid, TextField, Typography, FormControl, InputLabel, Select, MenuItem, Snackbar, Alert } from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const apiUrl = process.env.REACT_APP_API_URL;

export default function CreateTrimestre() {
    // State variables for form inputs
    const navigate = useNavigate();
    const [nom, setNom] = useState('');
    const [dateDebut, setDateDebut] = useState('');
    const [dateFin, setDateFin] = useState('');
    const [anneeScolaireId, setAnneeScolaireId] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);

    // State variable for available AnneeScolaire options
    const [anneeScolaires, setAnneeScolaires] = useState([]);

    // Fetch all available AnneeScolaire entries when the component mounts
    useEffect(() => {
        const fetchAnneeScolaires = async () => {
            try {
                const response = await axios.get(`${apiUrl}/anneescolaire/all`);
                setAnneeScolaires(response.data);
            } catch (error) {
                console.error('Error fetching AnneeScolaire:', error);
            }
        };

        fetchAnneeScolaires();
    }, []);

    // Function to handle form submission
    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            // Create a new Semestre entry
            await axios.post(`${apiUrl}/trimestre/new`, {
                nom,
                dateDebut,
                dateFin,
                anneeScolaireId, // Include the selected AnneeScolaire ID in the request
            });

            console.log("Semestre créé avec succès !");
            setOpenSnackbar(true);
            // Optionally, you can clear form fields or provide further feedback to the user
        } catch (error) {
            console.error('Error creating Semestre:', error);
            // Handle error, possibly provide user feedback
        }
    };

    return (
        <div>
            <Box sx={{ width: '100%' }}>
                <Typography variant="h6" mb={3}>
                    Créer un nouveau trimestre
                </Typography>

                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Nom"
                                value={nom}
                                placeholder="exemple: Premier trimestre 2024/2025"
                                onChange={(e) => setNom(e.target.value)}
                                fullWidth
                                margin="normal"
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Date Début"
                                type="date"
                                value={dateDebut}
                                onChange={(e) => setDateDebut(e.target.value)}
                                fullWidth
                                margin="normal"
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Date Fin"
                                type="date"
                                value={dateFin}
                                onChange={(e) => setDateFin(e.target.value)}
                                fullWidth
                                margin="normal"
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth margin="normal">
                                <InputLabel>Année Scolaire</InputLabel>
                                <Select
                                    value={anneeScolaireId}
                                    onChange={(e) => setAnneeScolaireId(e.target.value)}
                                    label="Année Scolaire"
                                >
                                    {anneeScolaires.map((anneeScolaire) => (
                                        <MenuItem key={anneeScolaire.id} value={anneeScolaire.id}>
                                            {anneeScolaire.nom}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <Button type="submit" variant="contained" color="primary">
                                Créer
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={2000}
                onClose={() => {
                    setOpenSnackbar(false);
                    navigate('/trimestre/all');
                }}
            >
                <Alert onClose={() => setOpenSnackbar(false)} severity="success" variant="filled">
                    Trimestre ajouté avec succès.
                </Alert>
            </Snackbar>
        </div>
    );
}
