import React, { useState } from 'react';
import { Box, TextField, Button, Typography, Snackbar, Alert, Grid } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const apiUrl = process.env.REACT_APP_API_URL;

export default function CreateAnneeScolaire() {
    // State variables for form inputs
    const [nom, setNom] = useState('');
    const [anneeDebut, setAnneeDebut] = useState('');
    const [anneeFin, setAnneeFin] = useState('');
    const navigate = useNavigate();
    const [openSnackbar, setOpenSnackbar] = useState(false);

    // Function to handle form submission
    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent default form submission

        try {
            // Create a new AnneeScolaire entry
            await axios.post(`${apiUrl}/anneescolaire/new`, {
                nom,
                anneeDebut,
                anneeFin,
            });
            setOpenSnackbar(true);
        } catch (error) {
            console.error('Error creating AnneeScolaire:', error);
        }
    };

    return (
        <div>
            <Box sx={{ width: '100%' }}>
                <Typography variant="h6" mb={3}>
                    Nouvelle année scolaire
                </Typography>

                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Année scolaire sous forme: yyyy/yyyy"
                                value={nom}
                                onChange={(e) => setNom(e.target.value)}
                                fullWidth
                                margin="normal"
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Année Début"
                                type="date"
                                value={anneeDebut}
                                onChange={(e) => setAnneeDebut(e.target.value)}
                                fullWidth
                                margin="normal"
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Année Fin"
                                type="date"
                                value={anneeFin}
                                onChange={(e) => setAnneeFin(e.target.value)}
                                fullWidth
                                margin="normal"
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                    </Grid>
                        <Button type="submit" variant="contained" color="primary">
                            Créer
                        </Button>
                    
                </form>
            </Box>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={1500}
                onClose={() => {
                    setOpenSnackbar(false);
                    navigate('/anneeacademique/all');
                }}
            >
                <Alert onClose={() => setOpenSnackbar(false)} severity='success' variant='filled'>
                    Année Scolaire ajoutée avec succès.
                </Alert>
            </Snackbar>
        </div>
    );
}
