import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Alert,
    Button,
    Snackbar,
} from '@mui/material'

const apiUrl = process.env.REACT_APP_API_URL;

const AfficherTrimestreById = () => {
    const [trimestre, setTrimestre] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        const fetchTrimestreById = async () => {
            try {
                const response = await axios.get(`${apiUrl}/trimestre/${id}`)
                setTrimestre(response.data);
            } catch (error) {
                console.log("Error fetching trimestre by id:", error);
            }
        };

        fetchTrimestreById();
    }, [id]);

    const handleDelete = async () => {
        const confirmDelete = window.confirm('Êtes-vous sûr·e de vouloir supprimer ce semestre scolaire ?');
        if(confirmDelete) {
            try {
                await axios.delete(`${apiUrl}/semestre/${id}`);
                console.log('Semestre deleted successfuly');
                setSnackbarOpen(true); // Open Snackbar after successful deletion
            } catch (error) {
                console.error("Error deleting semestre: ", error);
            }
        }
    };

    const handleEdit = async () => {
        navigate(`/trimestre/${id}/edit`);
    };

    // Check if anneeScolaire is null before rendering its properties
    if (!trimestre) {
        return <div>Loading...</div>; // or any other loading indicator
    }
    
    return (
        <div>
            <h2>Nom: {trimestre.nom}</h2>
            <p>Date Début: {new Date(trimestre.dateDebut).toLocaleDateString('en-GB')}</p>
            <p>Date Fin: {new Date(trimestre.dateFin).toLocaleDateString('en-GB')}</p>
            <Button onClick={handleDelete} variant="contained" color="error" startIcon={<DeleteIcon />}>Supprimer le trimestre</Button>
            <Button onClick={handleEdit} variant="contained" color="warning">modifier le trimestre</Button>

            {/* Snackbar component */}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={2000}
                onClose={() => {
                    setSnackbarOpen(false);
                    navigate('/semestreacademique/all');
                }}
            >
                <Alert onClose={() => setSnackbarOpen(false)} severity="success" variant='filled'>
                    Semestre supprimée avec succès
                </Alert>
            </Snackbar>
        </div>
    );
    
};

export default AfficherTrimestreById;