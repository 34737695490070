import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18next from './i18n';

import '/node_modules/flag-icons/css/flag-icons.min.css';

import Login from './pages/Login';
import Users from './pages/user/Users';
import Dashboard from './pages/Dashboard';
import SideBar from './components/SideBar';
import DarTalibas from './pages/darTaliba/DarTalibas';
import Associations from './pages/association/Associations';
import MaybeShowSideBar from './components/MaybeShowSideBar';
import AccueilResponsable from './pages/AccueilResponsable';
import RapportTrimestriel from './pages/reports/RapportTrimestriel';
import ResultatsAnneeScolaire from './pages/reports/ResultatsAnneeScolaire';
import CreateEntity from './pages/entity/CreateEntity';
import CreateResponsable from './pages/user/CreateUser';
import EntityById from './pages/entity/EntityById';
import EditEntity from './pages/entity/EditEntity';
import UserById from './pages/user/UserById';
import Header from './components/Header';
import AfficherResultatsAnneeScolaires from './pages/reports/AfficherResultatsAnneeScolaires';
import EditUser from './pages/user/EditUser';
import AfficherDistributionFinale from './pages/reports/AfficherDistributionFinale';
import CreateAnneeScolaire from './pages/periodeAcademique/anneeScolaire/CreateAnneeScolaire';
import CreateTrimestre from './pages/periodeAcademique/trimestre/CreateTrimestre';
import AfficherAnneesAcademiques from './pages/periodeAcademique/AfficherAnneesAcademiques';
import AfficherAnneeScolaireById from './pages/periodeAcademique/anneeScolaire/AfficherAnneeScolaireById';
import AfficherTrimestres from './pages/periodeAcademique/AfficherTrimestres';
import AfficherTrimestreById from './pages/periodeAcademique/trimestre/AfficherTrimestreById';
import EditAnneeScolaire from './pages/periodeAcademique/anneeScolaire/EditAnneeScolaire';
import EditSemestre from './pages/periodeAcademique/trimestre/EditTrimestre';
import EditResultatAnneeScolaireByEntity from './pages/reports/EditResultatAnneeScolaireByEntity';
import Products from './pages/product/Products';
import CreateProduct from './pages/product/CreateProduct';
import Categories from './pages/category/Categories';
import CreateCategory from './pages/category/CreateCategory';
import AfficherRapportsTrimestriels from './pages/reports/AfficherRapportsTrimestriels';
import EditRapportTrimestrielByEntity from './pages/reports/EditRapportTrimestrielByEntity';

function App() {

  useEffect(() => {
    const lng = i18next.language || 'fr'; // Set default language to 'fr'
    i18next.changeLanguage(lng);
    const sessionCookieExists = document.cookie.includes('connect.sid');
    if (!sessionCookieExists && window.location.pathname !== '/login') {
      window.location.href = '/login'; // Redirect to login page
    }
  }, []);
  
  return (
    <I18nextProvider i18next={i18next}>
    <div className="App">
      <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/accueilresponsable" element={<AccueilResponsable />} />
        <Route path='/resultatsanneescolaire/:entityId' element={<ResultatsAnneeScolaire />} />
        <Route path='/rapporttrimestriel/:entityId' element={<RapportTrimestriel />} />
      </Routes>  
        <div className="container">
         <MaybeShowSideBar><SideBar /></MaybeShowSideBar>
         <MaybeShowSideBar><Header /></MaybeShowSideBar>
          <div className="content">
            <Routes>
            <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/user/responsable/all" element={<Users />} />
              <Route path="/user/:id" element={<UserById />} />
              <Route path='/user/:id/edit' element={<EditUser />} />
              <Route path="/entity/dartaliba/all" element={<DarTalibas />} />
              <Route path="/user/new" element={<CreateResponsable />} />
              <Route path="/entity/new" element={<CreateEntity />} />
              <Route path="/entity/:id" element={<EntityById />} />
              <Route path="/resultatsanneescolaire/edit/:id" element={<EditResultatAnneeScolaireByEntity />} />
              <Route path="/rapporttrimestriel/edit/:id" element={<EditRapportTrimestrielByEntity />} />
              <Route path="/entity/:id/edit" element={<EditEntity />} />
              <Route path="/entity/association/all" element={<Associations />} />
              <Route path='/resultatsanneescolaires/all' element={<AfficherResultatsAnneeScolaires />} />
              <Route path='/rapportrimestriel/all' element={<AfficherRapportsTrimestriels />} />
              <Route path='/distributionFinale/all' element={<AfficherDistributionFinale />} />
              <Route path='/anneeacademique/all' element={<AfficherAnneesAcademiques />} />
              <Route path='/trimestre/all' element={<AfficherTrimestres />} />
              <Route path='/trimestre/:id' element={<AfficherTrimestreById />} />
              <Route path='/trimestre/:id/edit' element={<EditSemestre />} />
              <Route path='/anneescolaire/new' element={<CreateAnneeScolaire />} />
              <Route path="/anneescolaire/:id" element={<AfficherAnneeScolaireById />} />
              <Route path="/anneescolaire/:id/edit" element={<EditAnneeScolaire />} />
              <Route path='/trimestre/new' element={<CreateTrimestre />} />
              <Route path='/product/all' element={<Products />} />
              <Route path='/product/new' element={<CreateProduct />} />
              <Route path='/category/all' element={<Categories />} />
              <Route path='/category/new' element={<CreateCategory />} />
            </Routes>
          </div>
        </div>
      </Router>
    </div>
    </I18nextProvider>
  );
}

export default App;
