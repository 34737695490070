import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import "../styles/Login.css";

const apiUrl = process.env.REACT_APP_API_URL;

function Login() {
    const { t, i18n} = useTranslation();

    const switchLanguage = (language) => {
        i18n.changeLanguage(language);
    }
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        telephone: '',
        password: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    
    axios.defaults.withCredentials = true;
    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
            const response = await axios.post(`${apiUrl}/login`, {
                telephone: formData.telephone,
                password: formData.password
            });
    
            if (response.data.success) {
                const { Role } = response.data;    
                if (Role === 'Responsable') {
                    console.log('Login successful for responsible user!');
                    navigate('/accueilresponsable');
                } else if (Role === 'Admin') {
                    console.log('Login successful!');
                    navigate('/dashboard');
                } else {
                    console.log('Unknown role:', Role);
                    alert('An error occurred while logging in');
                }
            } else {
                alert('Invalid phone number or password');
            }
        } catch (error) {
            console.error('Error logging in:', error);
            alert('An error occurred while logging in');
        }
    };
    
    return (
        <div className="login-container">
            <div className="logo">
                <img src="/assets/logo.png" alt="logo" />
            </div>
            <div className="form-container">
                {/* Add clickable flag icons */}
                <div className='language-selector'>
                    <span className="language-option" onClick={() => switchLanguage('fr')}>Français</span> | 
                    <span className="language-option" onClick={() => switchLanguage('ar')}>العربية</span>
                </div>
                <h1 className="bvn">{t('welcome')}</h1>
                <h4 className="fillInfo">{t('Renseignez les champs')}</h4>
                <form onSubmit={handleSubmit}>
                    <div className="phoneField">
                        <input 
                            type="tel" 
                            id="telephone" 
                            name="telephone" 
                            placeholder={t('phoneNumber')}
                            value={formData.telephone} 
                            onChange={handleChange} 
                            required 
                        />
                    </div>
                    <div className="passwordField">
                        <input 
                            type="password" 
                            id="password" 
                            name="password" 
                            placeholder={t('password')}
                            value={formData.password} 
                            onChange={handleChange} 
                            required 
                        />
                    </div>
                    <button className="loginButton" type="submit">{t('signin')}</button>
                </form>
            </div>
        </div>
    );
}

export default Login;
