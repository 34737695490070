import React, { useState } from 'react';
import { Box, Stepper, Step, StepLabel, Button, Typography, Grid, TextField, Snackbar, Alert } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const steps = ['Informations personnelles', 'informations de connexion'];

export default function CreateResponsable() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(null);
  const [snackbarSeverity, setSnackbarSeverity] = useState(null);
  const [completed, setCompleted] = React.useState({});
  const [formValues, setFormValues] = React.useState({
    nom: '',
    prenom: '',
    telephone: '',
    role: 'Responsable',
    telephoneSecondaire: '',
    username: '',
    password: '',
    // Add more fields as needed
  });
  const navigate = useNavigate();
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const apiUrl = process.env.REACT_APP_API_URL;

  const totalSteps = () => {
    return steps.length;
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const validateForm = () => {
    const requiredFieldsByStep = {
      0: ['nom', 'prenom', 'telephone'],
      1: ['password'],
    };

    const requiredFields = requiredFieldsByStep[activeStep];

    // Check if all required fields are filled in
    for (const field of requiredFields) {
      const value = formValues[field];
      // Check if the value is empty or undefined
      if (value === null || value === undefined || value === '') {
          return false;
      }
    }
    // All required fields are filled in
    return true;
  };

  const handleComplete = async () => {
    // Validate the form
    if (!validateForm()) {
      // Display an error message or handle the error in some way
      setSnackbarMessage('Veuillez remplir tout les champs');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      return;
    }
    // Send data to backend on last step
    if (activeStep === totalSteps() - 1) {
      try {
        await axios.post(`${apiUrl}/user/new`, formValues);
        console.log('Responsable added successfully.');
        setSnackbarMessage('Responsable ajouté.e avec succès.');
        setSnackbarSeverity('success');
        setOpenSnackbar(true);
        //Redirect
        setTimeout(() => {
          navigate('/user/responsable/all');
        }, 2000);
      } catch (error) {
        console.error('Error adding Responsable:', error);
      }
    }
    handleNext();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  return (
    <div>
    <Box sx={{ width: '100%' }}>
      <Typography variant='h6' mb={3}>
        Nouveau Responsable
      </Typography>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box sx={{ mt: 5 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {activeStep === totalSteps() ? (
            <Box>
              <Typography sx={{ mt: 2, mb: 1 }}>
                Succès
              </Typography>
            </Box>
          ) : (
            <Box>
              <Box sx={{ mb: 2 }}>
                {activeStep === 0 && (
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField name="nom" label="Nom" value={formValues.nom} onChange={handleChange} variant="outlined" fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField name="prenom" label="Prenom" value={formValues.prenom} onChange={handleChange} variant="outlined" fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField name="telephone" label="Telephone" value={formValues.telephone} onChange={handleChange} variant="outlined" fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField name="telephoneSecondaire" label="Telephone Secondaire" value={formValues.telephoneSecondaire} onChange={handleChange} variant="outlined" fullWidth />
                    </Grid>
                  </Grid>
                )}
                {activeStep === 1 && (
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField name='password' label='Mot de passe' value={formValues.password} onChange={handleChange} variant='outlined' type='password' fullWidth />
                    </Grid>
                  </Grid>
                )}
              </Box>
              <Grid container spacing={2}>
                <Grid item>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                  >
                    Retour
                  </Button>
                </Grid>
                <Grid item xs />
                <Grid item>
                  <Button onClick={handleComplete}>
                    {activeStep === totalSteps() - 1 ? 'Terminer' : 'Suivant'}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )}
        </Grid>
      </Grid>
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={() => {
          setOpenSnackbar(false);
        }}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarSeverity} variant='filled'>
            {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
    </div>
  );
  
};
