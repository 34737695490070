import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Container, Grid, TextField, Typography, Snackbar, Alert } from '@mui/material';

const apiUrl = process.env.REACT_APP_API_URL;

const EditAnneeScolaire = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [anneeScolaire, setAnneeScolaire] = useState({});
    const [formData, setFormData] = useState({
        nom: "",
        anneeDebut: "",
        anneeFin: ""
    });
    const [nom, setNom] = useState('');
    const [anneeDebut, setAnneeDebut] = useState('');
    const [anneeFin, setAnneeFin] = useState('');

    useEffect(() => {
        const fetchAnneeScolaire = async () => {
            try {
                const response = await axios.get(`${apiUrl}/anneescolaire/${id}`);
                setAnneeScolaire(response.data);
                setFormData({
                    nom: response.data.nom,
                    anneeDebut: response.data.anneeDebut,
                    anneeFin: response.data.anneeFin
                });
            } catch (error) {
                console.error('Error fetching annee scolaire:', error);
            }
        };

        fetchAnneeScolaire();
    }, [id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        try {
            const formattedData = {
                nom: formData.nom,
                anneeDebut: new Date(formData.anneeDebut).toISOString(), // Convert to ISO string
                anneeFin: new Date(formData.anneeFin).toISOString() // Convert to ISO string
            };
    
            await axios.put(`${apiUrl}/anneescolaire/${id}/edit`, formattedData);
            // Redirect or show success message
            setSnackbarOpen(true);
        } catch (error) {
            console.error('Error updating annee scolaire:', error);
        }
    };
    

    const handleInputChange = async (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        })); 
    }

    return (
        <Container>
            <Typography variant='h4'>Modifier l'Année Scolaire</Typography>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Nom"
                            name="nom"
                            value={formData.nom}
                            onChange={handleInputChange}
                            fullWidth
                            margin='normal'
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Année Début"
                            name="anneeDebut"
                            type='date'
                            value={formData.anneeDebut}
                            onChange={handleInputChange}
                            fullWidth
                            margin='normal'
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Année Fin"
                            name="anneeFin"
                            type='date'
                            value={formData.anneeFin}
                            onChange={handleInputChange}
                            fullWidth
                            margin='normal'
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button type='submit' variant='contained' color='warning'>
                            Sauvgarder les changements
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={1000}
                onClose={() => {
                setSnackbarOpen(false);
                navigate(`/anneescolaire/${id}`);
                }}
            >
                <Alert onClose={() => setSnackbarOpen(false)} severity="success" variant="filled">
                Année scolaire modifié avec succès.
                </Alert>
            </Snackbar>
        </Container >
    );
};

export default EditAnneeScolaire;
