import React, { useState, useEffect } from "react";
import axios from "axios";
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HomeIcon from '@mui/icons-material/Home';
import LockResetIcon from '@mui/icons-material/LockReset';
import EditIcon from '@mui/icons-material/Edit';
import PersonIcon from '@mui/icons-material/Person';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import ReportIcon from '@mui/icons-material/Report';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { useNavigate, useParams } from "react-router-dom";
import { Snackbar, Alert, Accordion, AccordionSummary, Typography, Paper, Table, TableHead, AccordionDetails, TableContainer, TableRow, TableBody, TableCell, IconButton, Icon, Card, Grid, CardHeader, 
  CardContent, Box

 } from '@mui/material';

const apiUrl = process.env.REACT_APP_API_URL;

function EntityById() {
  const [entity, setEntity] = useState(null);
  const [resultatsAnneeScolaire, setResultatsAnneeScolaire] = useState([]);
  const [rapportsTrimestriels, setRapportsTrimestriels] = useState([]);
  const [responsables, setResponsables] = useState([]);
  const [anneeScolaireMap, setAnneeScolaireMap] = useState([]);
  const [trimestreMap, setSemestreMap] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const {id} = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch entity details
        const entityResponse = await axios.get(`${apiUrl}/entity/${id}`);
        setEntity(entityResponse.data);

        // Fetch Resultats Année Scolaire
        const resultatsAnneeScolaireResponse = await axios.get(`${apiUrl}/resultatsanneescolaire/byentity/${id}`);
        setResultatsAnneeScolaire(resultatsAnneeScolaireResponse.data);

        // Fetch Rapports Trimestriels
        const rapportsTrimestrielsResponse = await axios.get(`${apiUrl}/rapporttrimestriel/byentity/${id}`);
        setRapportsTrimestriels(rapportsTrimestrielsResponse.data);

        // Fetch Responsables
        const responsablesResponse = await axios.get(`${apiUrl}/user/responsable/all`);
        setResponsables(responsablesResponse.data);
        } catch (error) {
          console.error("Error fetching data:", error);
          // Handle error
        }
        // Fetch all AnneeScolaire entities
        const anneesScolairesResponse = await axios.get(`${apiUrl}/anneescolaire/all`);
        const anneeScolaireData = anneesScolairesResponse.data;
        const anneeScolaireNameMap = {};
        anneeScolaireData.forEach(anneeScolaire => {
          anneeScolaireNameMap[anneeScolaire.id] = anneeScolaire.nom;
        });
        setAnneeScolaireMap(anneeScolaireNameMap);

        // Fetch all Semestre entities
        const trimestresResponse = await axios.get(`${apiUrl}/trimestre/all`);
        const trimestreData = trimestresResponse.data;
        const trimestreNameMap = {};
        trimestreData.forEach(trimestre => {
          trimestreNameMap[trimestre.id] = trimestre.nom;
        });
        setSemestreMap(trimestreNameMap);
    };
    fetchData();
  }, [id]);

  const handleDownloadResultatAnneeScolaire = async (rapportId, anneeScolaireId) => {
    try {
      console.log('Downloading report...');
      const response = await axios.get(`${apiUrl}/downloadreport/anneescolaire/${rapportId}`, {
          responseType: 'blob', // Set responseType to blob for downloading binary data
      });
      console.log('Report downloaded successfully:', response);

      const entityName = entity?.nom || 'Unknown';  // Use entity from state

      // Fetch the school year name based on anneeScolaireId
      const anneeScolaireName = anneeScolaireMap[anneeScolaireId] || 'Unknown';  // Use anneeScolaireMap for names
      // Create a Blob object from the response data
      const blob = new Blob([response.data], { type: response.headers['content-type'] });

      // Create a URL for the Blob object
      const url = window.URL.createObjectURL(blob);

      // Create a link element to initiate the download
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `resultatsAnneeScolaire_${entityName}_${anneeScolaireName}.docx`);
      document.body.appendChild(link);

      // Trigger the download
      link.click();

      // Cleanup
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
        console.error('Error downloading report:', error);
    }
  };

  const handleDownloadRapportTrimestriel = async (rapportId, trimestreId) => {
    try {
        console.log('Downloading report...');
        const response = await axios.get(`${apiUrl}/downloadreport/trimestriel/${rapportId}`, {
            responseType: 'blob', // Set responseType to blob for downloading binary data
        });
        console.log('Report downloaded successfully:', response);

        const entityName = entity?.nom || 'Unknown';  // Use entity from state
        const trimestreName = trimestreMap[trimestreId] || 'Unknown';  // Use trimestreMap for names

        // Create a Blob object from the response data
        const blob = new Blob([response.data], { type: response.headers['content-type'] });

        // Create a URL for the Blob object
        const url = window.URL.createObjectURL(blob);

        // Create a link element to initiate the download
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `rapportTrimestriel_${entityName}_${trimestreName}.docx`);
        document.body.appendChild(link);

        // Trigger the download
        link.click();

        // Cleanup
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
    } catch (error) {
        console.error('Error downloading report:', error);
    }
  };

  const handleDelete = async () => {
    const confirmDelete = window.confirm('Êtes-vous sûr·e de vouloir supprimer cet entité ?');
    if (confirmDelete) {
      try {
        await axios.delete(`${apiUrl}/entity/${id}`);
        console.log('Entity deleted successfully');
        setSnackbarMessage('Entité supprimée avec succès');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        setTimeout(() => {
          navigate('/dashboard');
        }, 2000);
      } catch (error) {
        console.error('Error deleting entity:', error);
      }
    }
  };

  const handleEdit = () => {
    navigate(`/entity/${id}/edit`);
  };

  const handleResultatAnneeScolaire = (rapportId) => {
    navigate(`/resultatsanneescolaire/edit/${rapportId}`);
  };

  const handleRapportTrimestriel = (rapportId) => {
    navigate(`/rapporttrimestriel/edit/${rapportId}`);
  };

  const responsable = responsables.find(responsable => responsable.id === entity?.UserId);

  const resetRapportAnneeScolaire = async () => {
    const confirmReset = window.confirm('Etes vous sur de vouloir donner l\'access pour l\'envoi de rapport année scolaire ?');
    if (confirmReset) {
      try {
        await axios.put(`${apiUrl}/entity/${id}/resultatsanneescolaire/reset`);
        setSnackbarMessage('Rapport Année Scolaire réinitialisé avec succès');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      } catch (error) {
        console.error('Error resetting Rapport Année Scolaire:', error);
        // Handle error
      }
    }
  };
  

  const resetRapportDetaille = async () => {
    const confirmReset = window.confirm('Etes vous sur de vouloir donner l\'access pour l\'envoi de rapport detaille ?');
    if (confirmReset) {
      try {
        await axios.put(`${apiUrl}/entity/${id}/rapportdetaille/reset`);
        setSnackbarMessage('Rapport Detaillé réinitialisé avec succès');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      } catch (error) {
        console.error('Error resetting Rapport Detaille:', error);
        // Handle error
      }
    }
  };
  
  return (
    <div>
      {entity && (
        <div>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Card>
                <CardHeader avatar={<HomeIcon />} title="Détails de l'Entité" />
                <CardContent>
                  <Typography variant="body2" color="textSecondary">Nom: {entity.nom}</Typography>
                  <Typography variant="body2" color="textSecondary">Type: {entity.typeEntity}</Typography>
                  <Typography variant="body2" color="textSecondary">Numero d'autorisation: {entity.numeroAutorisation}</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card>
                <CardHeader avatar={<PersonIcon />} title="Responsable" />
                <CardContent>
                  <Typography variant="body2" color="textSecondary">Responsable: {responsable && `${responsable.nom} ${responsable.prenom}`}</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card>
                <CardHeader avatar={<ReportIcon />} title="Rapports" />
                <CardContent>
                  <Typography variant="body2" color="textSecondary">Rapport des résultats d'année scolaire soumis ? : {entity.isResultatsAnneeScolaireSubmitted ? 'oui' : 'non'}</Typography>
                  <Typography variant="body2" color="textSecondary">Rapport Trimestriel soumis ? : {entity.isRapportTrimestrielSubmitted ? 'oui' : 'non'}</Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Grid container spacing={3} mt={2}>
            <Grid item xs={12} md={6}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Resultats Année Scolaire</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Année scolaire</TableCell>
                          <TableCell>Modifier</TableCell>
                          <TableCell>Télécharger</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {resultatsAnneeScolaire.map((rapport) => (
                          <TableRow key={rapport.id}>
                            <TableCell>{anneeScolaireMap[rapport.AnneeScolaireId] || 'Unknown'}</TableCell>
                            <TableCell>
                              <IconButton onClick={() => handleResultatAnneeScolaire(rapport.id)}>
                                <BorderColorIcon />
                              </IconButton>
                            </TableCell>
                            <TableCell>
                              <IconButton onClick={() => handleDownloadResultatAnneeScolaire(rapport.id, rapport.AnneeScolaireId)} variant="contained"><CloudDownloadIcon /></IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12} md={6}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Rapports Trimestriels</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Trimestre</TableCell>
                          <TableCell>Modifier</TableCell>
                          <TableCell>Télécharger</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rapportsTrimestriels.map((rapport) => (
                          <TableRow key={rapport.id}>
                            <TableCell>{trimestreMap[rapport.TrimestreId] || 'Unknown'}</TableCell>
                            <TableCell>
                              <IconButton onClick={() => handleRapportTrimestriel(rapport.id)}>
                                <BorderColorIcon />
                              </IconButton>
                            </TableCell>
                            <TableCell>
                              <IconButton onClick={() => handleDownloadRapportTrimestriel(rapport.id, rapport.TrimestreId)}><CloudDownloadIcon /></IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
          {/* ACTION BUTTONS */}
          <div style={{ position: 'fixed', bottom: 20, left: 225, right: 20, display: 'flex', justifyContent: 'center', zIndex: 1000 }}>
            <Box>
              <Grid container spacing={2}>
                <Grid item>
                  <Button onClick={handleDelete} variant="contained" color="error" startIcon={<DeleteIcon />}>
                    Supprimer l'entité
                  </Button>
                </Grid>
                <Grid item>
                  <Button onClick={handleEdit} variant="contained" color="warning" startIcon={<EditIcon />}>
                    Modifier l'entité
                  </Button>
                </Grid>
                <Grid item>
                  <Button onClick={resetRapportAnneeScolaire} variant="contained" startIcon={<LockResetIcon />}>
                    Reinitialiser l'envoi pour resultat scolaire
                  </Button>
                </Grid>
                <Grid item>
                  <Button onClick={resetRapportDetaille} variant="contained" startIcon={<LockResetIcon />}>
                    Reinitialiser l'envoi pour rapport trimestriel
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </div>
          
        </div>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} variant="filled">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default EntityById;
