import Reac, { useState } from "react";
import "../App.scss";
import { Link } from 'react-router-dom';
import { SideBarData } from "./SideBarData"; // Correct casing
import { ListItemIcon } from '@mui/material'; // Import ListItemIcon from Material-UI

function SideBar() {

    // State to track the selected nav item
    const [selectedIndex, setSelectedIndex] = useState(null);

    // Function to handle nav item click
    const handleNavItemClick = (index) => {
        setSelectedIndex(index);
    };

    return (
        <div className="Sidebar">
            <div className="logo-container">
                <img src="/assets/logo.png" className="Logo" alt="logo" />
            </div>
            <ul className="sidenav-nav">
                {SideBarData.map((data, index) => (
                    <li className="sidenav-nav-item" key={index}>
                        <Link
                            to={data.routeLink} 
                            className={`sidenav-nav-link ${selectedIndex === index ? 'selected' : ''}`}
                            onClick={() => handleNavItemClick(index)}
                        >
                        <ListItemIcon className="sidenav-link-icon">
                                {data.icon}
                        </ListItemIcon>
                        <span className="sidenav-link-text">{data.label}</span>
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default SideBar;
