import React, { useState, useEffect } from 'react';
import { Stepper, Step, StepLabel, Button, Typography, Container, IconButton, TextField, Grid, MenuItem, Snackbar, Alert } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import '../../styles/RapportAnneeScolaire.scss';

const apiUrl = process.env.REACT_APP_API_URL;

const EditResultatAnneeScolaireByEntity = () => {
  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate();
  const { id } = useParams();
  const [anneeScolaires, setAnneeScolaires] = useState([]);
  const [academiesRegionales, setAcademiesRegionales] = useState([]);
  const [entity, setEntity] = useState([]); 
  const [prefectures, setPrefectures] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [provinces, setProvinces] = useState([]);
  const [regions, setRegions] = useState([]);
  const [formValues, setFormValues] = useState({
    nbPersonnel: '',
    nbBeneficiaireFeminin: '',
    nbBeneficiaireMasculin: '',
    nbAbandonScolaireFeminin: '',
    nbAbandonScolaireMasculin: '',
    nbFemininReussi: '',
    nbMasculinReussi: '',
    nbCandidatFemininBac: '',
    nbCandidatMasculinBac: '',
    nbReussiteBacFeminin: '',
    nbReussiteBacMasculin: '',
    AnneeScolaireId: '',
    EntityId: 0,
    pourcentageReussiteFeminin: '',
    pourcentageReussiteMasculin: ''
  });

  const steps = [
    'Informations de bases', // Translate the string using the t function
    'Bénéficiaires', // Translate the string using the t function
    'Informations academiques des beneficiaires' // Translate the string using the t function
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch existing report data
        if (id) {
          const response = await axios.get(`${apiUrl}/resultatsanneescolaire/${id}`);
          setFormValues(response.data);
        }
        // Fetch Annee Scolaires
        const anneeScolairesResponse = await axios.get(`${apiUrl}/anneescolaire/all`);
        setAnneeScolaires(anneeScolairesResponse.data);
        // Fetch Academies Regionales
        const academiesRegionalesResponse = await axios.get(`${apiUrl}/academieregionale/all`);
        setAcademiesRegionales(academiesRegionalesResponse.data);
        // Fetch region data
        const regionsResponse = await axios.get(`${apiUrl}/region/all`);
        setRegions(regionsResponse.data);
        // Fetch prefecture data
        const prefecturesResponse = await axios.get(`${apiUrl}/prefecture/all`);
        setPrefectures(prefecturesResponse.data);
        // Fetch province data
        const provincesResponse = await axios.get(`${apiUrl}/province/all`);
        setProvinces(provincesResponse.data);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          navigate('/login');
        } else {
          console.error('Error:', error);
        }
      }
    };
  
    fetchData();
  }, [id]);
  
  const handleNext = () => {
    if (activeStep === totalSteps() - 1) {
      // If on the last step, submit the form
      handleSubmit();
    } else {
      // Otherwise, proceed to the next step
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };
  
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const totalSteps = () => {
    return steps.length;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Update formValues using callback form of setFormValues
    setFormValues(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async () => {
    const rapportData = {
      nbPersonnel: parseInt(formValues.nbPersonnel, 10),
      nbBeneficiaireFeminin: parseInt(formValues.nbBeneficiaireFeminin, 10),
      nbBeneficiaireMasculin: parseInt(formValues.nbBeneficiaireMasculin, 10),
      nbAbandonScolaireFeminin: parseInt(formValues.nbAbandonScolaireFeminin, 10),
      nbAbandonScolaireMasculin: parseInt(formValues.nbAbandonScolaireMasculin, 10),
      nbFemininReussi: parseInt(formValues.nbFemininReussi, 10),
      nbMasculinReussi: parseInt(formValues.nbMasculinReussi, 10),
      nbCandidatFemininBac: parseInt(formValues.nbCandidatFemininBac, 10),
      nbCandidatMasculinBac: parseInt(formValues.nbCandidatMasculinBac, 10),
      nbReussiteBacFeminin: parseInt(formValues.nbReussiteBacFeminin, 10),
      nbReussiteBacMasculin: parseInt(formValues.nbReussiteBacMasculin, 10),
      AnneeScolaireId: parseInt(formValues.AnneeScolaireId, 10),
      EntityId: formValues.EntityId,
      pourcentageReussiteFeminin: parseFloat(formValues.pourcentageReussiteFeminin),
      pourcentageReussiteMasculin: parseFloat(formValues.pourcentageReussiteMasculin)
    };
    
    try {
      if (id) {
        await axios.put(`${apiUrl}/resultatsanneescolaire/${id}`, rapportData);
        setSnackbarMessage('Resultat Année Scolaire modifié avec succès');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        console.log('Rapport updated successfully!');
      }
      // Navigate to dashboard after 2 seconds
      setTimeout(() => {
        navigate('/dashboard');
      }, 2000);
    } catch (error) {
      console.error('Error submitting form:', error);
      setSnackbarMessage("Aucun changement n'a été détecté");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };
  
  return (
    <div>
      <Container>
        <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }} className="stepper-form">
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step key={index}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          {activeStep === 0 && (
            <Grid container spacing={2} mt={2} mb={2}>
              <Grid item xs={6}>
                <TextField
                  label="Nombre de personnel"
                  name="nbPersonnel"
                  value={formValues.nbPersonnel}
                  onChange={handleInputChange}
                  type="number"
                  required
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Année scolaire"
                  name="AnneeScolaireId"
                  value={formValues.AnneeScolaireId}
                  onChange={handleInputChange}
                  select
                  required
                  fullWidth
                  margin="normal"
                >
                  {anneeScolaires.map((anneeScolaire) => (
                    <MenuItem key={anneeScolaire.id} value={anneeScolaire.id}>
                      {anneeScolaire.nom}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          )}

          {activeStep === 1 && (
            <Grid container spacing={2} mt={2} mb={2}>
              <Grid item xs={6}>
                <TextField
                  label='Nombre de beneficiaires feminin'
                  name="nbBeneficiaireFeminin"
                  value={formValues.nbBeneficiaireFeminin}
                  onChange={handleInputChange}
                  type="number"
                  required
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label='Nombre de beneficiaires masculin'
                  name="nbBeneficiaireMasculin"
                  value={formValues.nbBeneficiaireMasculin}
                  onChange={handleInputChange}
                  type="number"
                  required
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Nombre d'abandon scolaire feminin"
                  name="nbAbandonScolaireFeminin"
                  value={formValues.nbAbandonScolaireFeminin}
                  onChange={handleInputChange}
                  type="number"
                  required
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Nombre d'abandon scolaire masculin"
                  name="nbAbandonScolaireMasculin"
                  value={formValues.nbAbandonScolaireMasculin}
                  onChange={handleInputChange}
                  type="number"
                  required
                  fullWidth
                  margin="normal"
                />
              </Grid>
            </Grid>
          )}

          {activeStep === 2 && (
            <Grid container spacing={2} mt={2} mb={2}>
              <Grid item xs={6}>
                <TextField
                  label='Nombre de reussite feminin'
                  name="nbFemininReussi"
                  value={formValues.nbFemininReussi}
                  onChange={handleInputChange}
                  type="number"
                  required
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label='Nombre de reussite masculin'
                  name="nbMasculinReussi"
                  value={formValues.nbMasculinReussi}
                  onChange={handleInputChange}
                  type="number"
                  required
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                    <TextField
                      name="nbCandidatFemininBac"
                      label="Nombre candidat baccalaureat feminin"
                      type="number"
                      fullWidth
                      value={formValues.nbCandidatFemininBac}
                      onChange={handleInputChange}
                    />
              </Grid>
              <Grid item xs={12} sm={3}>
                    <TextField
                      name="nbCandidatMasculinBac"
                      label="Nombre candidat baccalaureat masculin"
                      type="number"
                      fullWidth
                      value={formValues.nbCandidatMasculinBac}
                      onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextField
                      name="nbReussiteBacFeminin"
                      label="Nombre reussite baccalaureat feminin"
                      type="number"
                      fullWidth
                      value={formValues.nbReussiteBacFeminin}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="nbReussiteBacMasculin"
                      label="Nombre reussite baccalaureat feminin"
                      type="number"
                      fullWidth
                      value={formValues.nbReussiteBacMasculin}
                      onChange={handleInputChange}
                    />
                  </Grid>
            </Grid>
          )}


          <div className="stepper-buttons">
            <Button disabled={activeStep === 0} onClick={handleBack}>
              {('retour')}
            </Button>
            <Button variant="contained" color="primary" onClick={handleNext}>
              {activeStep === totalSteps() - 1 ? ('Mettre à jour') : ('suivant')}
            </Button>
          </div>
        </form>
      </Container>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} variant="filled">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default EditResultatAnneeScolaireByEntity;
