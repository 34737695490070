import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, IconButton, Button, Typography } from '@mui/material';
import axios from 'axios'; // Import axios library for making HTTP requests
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';

const apiUrl = process.env.REACT_APP_API_URL;

const User = () => {
    const [data, setData] = useState([]); // State to hold the fetched data
    const [searchTerm, setSearchTerm] = useState('');

    // Fetching the Data
    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch User data
                const userResponse = await axios.get(`${apiUrl}/user/responsable/all`);
                setData(userResponse.data); // Set the fetched data to the state

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData(); // Call the fetchData function when the component mounts
    }, []); 

    // Filter data based on search term
    const filteredData = data.filter(item =>
        item.nom.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.prenom.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div>
            <Typography variant='h6'>Liste des responsables</Typography>
            <TextField
                label="Nom..."
                variant="outlined"
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{ marginBottom: '20px', marginTop: '20px' }}
            />
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Responsable</TableCell>
                            <TableCell>Téléphone</TableCell>
                            <TableCell>Téléphone Secondaire</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredData.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell>{row.nom} {row.prenom}</TableCell>
                                <TableCell>{row.telephone}</TableCell>
                                <TableCell>{row.telephoneSecondaire}</TableCell>
                                <TableCell><IconButton component={Link} to={`/user/${row.id}`}><RemoveRedEyeOutlinedIcon/></IconButton></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default User;
