import React, {useState, useEffect} from 'react';
import axios from 'axios';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { Table,TextField, TableHead, TableBody, TableRow, TableCell, Paper, TableContainer, Snackbar, Alert, Typography } from '@mui/material'

const apiUrl = process.env.REACT_APP_API_URL;

const AfficherRapportsTrimestriels = () => {
    const [rapportsTrimestriels, setRapportsTrimestriels] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [anneeScolaires, setAnneeScolaires] = useState({});
    const [trimestres, setTrimestres] = useState({});
    const [entities, setEntities] = useState([]);
    const [users, setUsers] = useState({});
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [rapportsResponse, entitiesResponse, anneeScolairesResponse, trimestresResponse, usersResponse] = await Promise.all([
                    axios.get(`${apiUrl}/rapporttrimestriel/all`),
                    axios.get(`${apiUrl}/entity/all`),
                    axios.get(`${apiUrl}/anneescolaire/all`),
                    axios.get(`${apiUrl}/trimestre/all`),
                    axios.get(`${apiUrl}/user/all`),
                ]);

                const rapportData = rapportsResponse.data;
                const entitiesData = entitiesResponse.data.reduce((acc, entity) => {
                    acc[entity.id] = entity;
                    return acc;
                }, {});
                const anneeScolairesData = anneeScolairesResponse.data.reduce((acc, year) => {
                    acc[year.id] = year;
                    return acc;
                }, {});

                const usersData = usersResponse.data.reduce((acc, user) => {
                    acc[user.id] = user;
                    return acc;
                }, {});
                
                const trimestresData = trimestresResponse.data.reduce((acc, trimestre) => {
                    acc[trimestre.id] = trimestre;
                    return acc;
                }, {});

                setRapportsTrimestriels(rapportData);
                setEntities(entitiesData);
                setAnneeScolaires(anneeScolairesData);
                setTrimestres(trimestresData);
                setUsers(usersData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const handleDownloadReport = async (id, entityId, trimestreId) => {
        try {
            const response = await axios.get(`${apiUrl}/downloadreport/trimestriel/${id}`, {
                responseType: 'blob',
            });
            console.log('Report Downloaded successfully:', response);
            // Fetch the entity name based on entityId
            const entityName = entities[entityId]?.nom || 'Unknown';
            // Fetch the school year name based on anneeScolaireId
            const trimestreName = trimestres[trimestreId]?.nom || 'Unknown';
            // Create a Blob object from the response data
            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            // Create a URL for the Blob object
            const url = window.URL.createObjectURL(blob);
            // Create a link element to initiate the download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `rapportDetaille_${entityName}_${trimestreName}.docx`);
            document.body.appendChild(link);

             // Trigger the download
             link.click();

             // Cleanup
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading report:', error);
        }
    };

    const handleDelete = async (id) => {
        // Prompt the user for confirmation
    const confirmDelete = window.confirm('Êtes-vous sûr·e de vouloir supprimer ce rapport ?');
        if (confirmDelete) {
            try {
                await axios.delete(`${apiUrl}/rapportdetaille/${id}`);
                setRapportsTrimestriels(rapportsTrimestriels.filter(rapport => rapport.id !== id)); // Remove the deleted report from the state
                setSnackbarOpen(true); // Open snackbar after successful deletion
            } catch (error) {
                console.error("Error deleting rapport detaille:", error);
            }
        }  
    };

    // Filter the data based on search query
    const filteredRapportsTrimestriels = rapportsTrimestriels.filter((row) => {
        const entityName = entities[row.EntityId]?.nom?.toLowerCase() || '';
        const trimestreName = trimestres[row.trimestreId]?.nom?.toLowerCase() || '';
        const responsableName = `${users[entities[row.EntityId]?.UserId]?.nom?.toLowerCase()} ${users[entities[row.EntityId]?.UserId]?.prenom?.toLowerCase()}`.trim();

        // Check if any of the names match the search query
        return (
            entityName.includes(searchQuery.toLowerCase()) ||
            trimestreName.includes(searchQuery.toLowerCase()) ||
            responsableName.includes(searchQuery.toLowerCase())
        );
    });

    return (
        <div>
            <Typography variant='h6'>Liste des rapports trimestriels</Typography>
            <TextField
                label="Chercher ..."
                variant="outlined"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                style={{ marginBottom: '20px', marginTop: '20px' }}
            />
            <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Entité</TableCell>
                        <TableCell>Trimestre Scolaire</TableCell>
                        <TableCell>Responsable</TableCell>
                        <TableCell>Télécharger</TableCell>
                        <TableCell>Supprimer</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filteredRapportsTrimestriels.map((row, index) => (
                        <TableRow key={index}>
                            <TableCell>{entities[row.EntityId]?.nom}</TableCell>
                            <TableCell>{trimestres[row.TrimestreId]?.nom}</TableCell>
                            <TableCell>{users[entities[row.EntityId]?.UserId]?.nom} {users[entities[row.EntityId]?.UserId]?.prenom}</TableCell>
                            <TableCell>
                                <IconButton onClick={() => handleDownloadReport(row.id, row.EntityId, row.trimestreId)}><CloudDownloadIcon /></IconButton>
                            </TableCell>
                            <TableCell>
                                <IconButton onClick={() => handleDelete(row.id)}><DeleteIcon /></IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            </TableContainer>
            <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={() => setSnackbarOpen(false)}>
                <Alert onClose={() => setSnackbarOpen(false)} severity="success" variant='filled' sx={{ width: '100%' }}>
                    Rapport supprimé avec succès.
                </Alert>
            </Snackbar>
        </div>
    );
};

export default AfficherRapportsTrimestriels;