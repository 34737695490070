import React from "react";
import SpaceDashboardOutlinedIcon from '@mui/icons-material/SpaceDashboardOutlined';
import VolunteerActivismOutlinedIcon from '@mui/icons-material/VolunteerActivismOutlined';
import HolidayVillageOutlinedIcon from '@mui/icons-material/HolidayVillageOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';

export const SideBarData = [
    {
        routeLink: "/dashboard",
        icon: <SpaceDashboardOutlinedIcon />,
        label: 'Dashboard'
    },    
    {
        routeLink: '/entity/dartaliba/all',
        icon: <HolidayVillageOutlinedIcon/>,
        label: 'Dar Talibat',
    },
    {
        routeLink: 'entity/association/all',
        icon: <VolunteerActivismOutlinedIcon/>,
        label: 'Associations'
    },
    {
        routeLink: 'user/responsable/all',
        icon: <PeopleAltOutlinedIcon/>,
        label: 'Responsables'
    },
    {
        routeLink: 'resultatsanneescolaires/all',
        icon: <SchoolOutlinedIcon/>,
        label: 'Resultats des années scolaires'
    },
    {
        routeLink: '/rapportrimestriel/all',
        icon: <Inventory2OutlinedIcon/>,
        label: 'Rapports trimestriels'
    },
    {
        routeLink: '/distributionFinale/all',
        icon: <PaidOutlinedIcon/>,
        label: 'Bilan des dotations trimestrielles'
    },
    {
        routeLink: '/anneeacademique/all',
        icon: <CalendarMonthOutlinedIcon/>,
        label: 'Années scolaire Academiques'
    },
    {
        routeLink: '/trimestre/all',
        icon: <DateRangeOutlinedIcon/>,
        label: 'Trimestres'
    },
    {
        routeLink: '/distributionFinale/all',
        icon: <PaidOutlinedIcon/>,
        label: 'Distribution finale des zones rurales'
    },
    {
        routeLink: '/anneeacademique/all',
        icon: <CalendarMonthOutlinedIcon/>,
        label: 'Années scolaire Academiques'
    },
    {
        routeLink: '/semestreacademique/all',
        icon: <DateRangeOutlinedIcon/>,
        label: 'Semestres Academiques'
    },
];