import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import {
  Container,
  Grid,
  TextField,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Radio,
  RadioGroup,
  FormControlLabel,
  Snackbar,
  Alert
} from "@mui/material";

const apiUrl = process.env.REACT_APP_API_URL;

export default function EditEntity() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [entity, setEntity] = useState(null);
  const [regions, setRegions] = useState([]);
  const [prefectures, setPrefectures] = useState([]); 
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [provinces, setProvinces] = useState([]);
  const [responsables, setResponsables] = useState([]);
  const [formData, setFormData] = useState({
    nom: "",
    typeEntity: "",
    numeroAutorisation: "",
    telephone: "",
    telephoneFixe: "",
    adresse: "",
    totalBoys: "",
    totalGirls: "",
    totalEtablissements: "",
    locationId: "",
    locationType: "",
    region: "",
    UserId: ""
  });

  useEffect(() => {
    // Fetch entity data by ID
    const fetchEntityById = async () => {
        try {
            const response = await axios.get(`${apiUrl}/entity/${id}`);
            setEntity(response.data);
            // Initialize form data with fetched entity data
            setFormData({
                nom: response.data.nom,
                typeEntity: response.data.typeEntity,
                numeroAutorisation: response.data.numeroAutorisation,
                telephone: response.data.telephone,
                telephoneFixe: response.data.telephoneFixe,
                adresse: response.data.adresse,
                totalBoys: response.data.totalBoys,
                totalGirls: response.data.totalGirls,
                totalEtablissements: response.data.totalEtablissements,
                locationId: response.data.locationId,
                locationType: response.data.locationType,
                UserId: response.data.UserId
            });
            console.log(formData);
        } catch (error) {
            console.error("Error fetching entity by id:", error);
        }
    };

    const fetchResponsables = async () => {
      try {
          const response = await axios.get(`${apiUrl}/user/responsable/all`);
          setResponsables(response.data);
      } catch (error) {
          console.error("Error fetching responsables:", error);
      }
    };

    const fetchRegions = async () => {
      try {
        const regionResponse = await axios.get('http://localhost:3001/region/all');
        setRegions(regionResponse.data)
      } catch (error) {
        console.error('Error fetching regions:', error);
      }
    };

    fetchEntityById();
    fetchResponsables();
    fetchRegions();
  }, [id]);

  // Handle form input changes
  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
        ...prevData,
        [name]: value
    }));

    if (name === 'region') {
        // Fetch the related prefectures and provinces when region changes
        try {
            const prefecturesResponse = await axios.get(`${apiUrl}/region/${value}/prefectures`);
            setPrefectures(prefecturesResponse.data);
            
            const provincesResponse = await axios.get(`${apiUrl}/region/${value}/provinces`);
            setProvinces(provincesResponse.data);
            
            // Reset location type and ID when region changes
            setFormData((prevData) => ({
                ...prevData,
                locationType: "",
                locationId: ""
            }));
        } catch (error) {
            console.error("Error fetching prefectures and provinces:", error);
        }
    } else if (name === 'locationType') {
        // Reset locationId when locationType changes
        setFormData((prevData) => ({
            ...prevData,
            locationType: value,
            locationId: ""
        }));
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
      e.preventDefault();
      try {
          const response = await axios.put(`${apiUrl}/entity/${id}/edit`, formData);
          console.log("Entity updated successfully:", response.data);
          // Optionally navigate to the entity detail page after updating
          setSnackbarOpen(true);
      } catch (error) {
          console.error("Error updating entity:", error);
      }
  };

  // Return JSX for the edit form
  return (
    <Container>
      <Typography variant="h4" gutterBottom>
          Modifier l'entité
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          {/* First row */}
          <Grid item xs={12} sm={6}>
              <TextField
                  label="Nom"
                  name="nom"
                  value={formData.nom}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
              />
          </Grid>
          <Grid item xs={12} sm={6}>
              <FormControl fullWidth margin="normal">
                  <InputLabel>DarTaliba/Association</InputLabel>
                  <Select
                      value={formData.typeEntity}
                      onChange={handleInputChange}
                      name="typeEntity"
                  >
                      <MenuItem value="DarTaliba">DarTaliba</MenuItem>
                      <MenuItem value="Association">Association</MenuItem>
                  </Select>
              </FormControl>
          </Grid>               
          {/* Second row */}
          <Grid item xs={12} sm={6}>
            <TextField
                label="Telephone"
                name="telephone"
                value={formData.telephone}
                onChange={handleInputChange}
                fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
                label="Telephone Fixe"
                name="telephoneFixe"
                value={formData.telephoneFixe}
                onChange={handleInputChange}
                fullWidth
            />
          </Grid>

          {/* Third row */}
          <Grid item xs={12} sm={6}>
            <TextField
                label="Adresse"
                name="adresse"
                value={formData.adresse}
                onChange={handleInputChange}
                fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
                label="Nombre total de garçons"
                name="totalBoys"
                type="number"
                value={formData.totalBoys}
                onChange={handleInputChange}
                fullWidth
            />
          </Grid>

          {/* Fourth row */}
          <Grid item xs={12} sm={6}>
            <TextField
                label="Nombre total de filles"
                name="totalGirls"
                type="number"
                value={formData.totalGirls}
                onChange={handleInputChange}
                fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
                label="Nombre d'établissement(s)"
                name="totalEtablissements"
                type="number"
                value={formData.totalEtablissements}
                onChange={handleInputChange}
                fullWidth
            />
          </Grid>
          {/* fifth row */}
          <Grid item xs={12} sm={12}>
              <Grid>
                <FormControl fullWidth margin='normal'>
                  <InputLabel>Région</InputLabel>
                  <Select
                    label="Région"
                    name="region"
                    value={formData.region}
                    onChange={handleInputChange}
                    fullWidth
                  >
                    {regions.map(region => (
                      <MenuItem key={region.id} value={region.id}>
                        {region.nom}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              {formData.region && (
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="locationType"
                    value={formData.locationType}
                    onChange={handleInputChange} // Add this line
                  >
                    <FormControlLabel value="prefecture" control={<Radio />} label="prefecture" />
                    <FormControlLabel value="province" control={<Radio />} label="province" />
                  </RadioGroup>
                </FormControl>
              )}
              </Grid>
              {formData.locationType === 'prefecture' && (
                <Grid item xs={6}>
                  <FormControl fullWidth margin='normal'>
                    <InputLabel>Préfecture</InputLabel>
                    <Select
                      label="Préfecture"
                      name="locationId"
                      value={formData.locationId}
                      onChange={handleInputChange}
                      fullWidth
                    >
                      {prefectures.map(prefecture => (
                        <MenuItem key={prefecture.id} value={prefecture.id}>
                          {prefecture.nom}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              {formData.locationType === 'province' && (
                <Grid item xs={6}>
                  <FormControl fullWidth margin='normal'>
                    <InputLabel>Province</InputLabel>
                    <Select
                      label="Province"
                      name="locationId"
                      value={formData.locationId}
                      onChange={handleInputChange}
                      fullWidth
                    >
                      {provinces.map(province => (
                        <MenuItem key={province.id} value={province.id}>
                          {province.nom}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
                label="Numero d'autorisation"
                name="numeroAutorisation"
                value={formData.numeroAutorisation}
                onChange={handleInputChange}
                fullWidth
            />
          </Grid>
          {/* Sixth row */}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
                <InputLabel>Responsable</InputLabel>
                <Select
                    name="UserId"
                    value={formData.UserId}
                    onChange={handleInputChange}
                >
                    {responsables.map((responsable) => (
                        <MenuItem key={responsable.id} value={responsable.id}>
                            {responsable.nom} {responsable.prenom}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
          </Grid>

          {/* Submission button */}
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="warning">
                Sauvegarder les changements
            </Button>
          </Grid>
        </Grid>
      </form>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={1000}
        onClose={() => {
          setSnackbarOpen(false);
          navigate(`/entity/${id}`);
        }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity="success" variant="filled">
          Entité modifié avec succès.
        </Alert>
      </Snackbar>
    </Container>
  );
}
