import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import {
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Table,
    IconButton,
    Typography,
    Box
} from '@mui/material';

const apiUrl = process.env.REACT_APP_API_URL;

const AfficherTrimestres = () => {
    const [trimestres, setTrimestres] = useState([]);
    const [anneesScolaires, setAnneesScolaires] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [trimestresResponse, anneesScolairesResponse] = await Promise.all([
                    axios.get(`${apiUrl}/trimestre/all`),
                    axios.get(`${apiUrl}/anneescolaire/all`),
                ]);
                const trimestresData = trimestresResponse.data;
                const anneesScolairesData = anneesScolairesResponse.data;

                // Fetch and attach the associated school year data for each semester
                const trimestresWithAnneeScolaire = trimestresData.map((trimestre) => ({
                    ...trimestre,
                    anneeScolaire: anneesScolairesData.find((annee) => annee.id === trimestre.AnneeScolaireId),
                }));

                setTrimestres(trimestresWithAnneeScolaire);
                setAnneesScolaires(anneesScolairesData);
            } catch (error) {
                console.error('Error fetching trimestres:', error);
            }
        };
        fetchData();
    }, []);

    return (
        <Box>
            <Typography variant='h6'>Liste des trimestres</Typography>
            <Box mt={2}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Trimestre</TableCell>
                                <TableCell>Date début</TableCell>
                                <TableCell>Date fin</TableCell>
                                <TableCell>Année scolaire</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {trimestres.map((trimestre) => (
                                <TableRow key={trimestre.id}>
                                    <TableCell>{trimestre.nom}</TableCell>
                                    <TableCell>{new Date(trimestre.dateDebut).toLocaleDateString('en-GB')}</TableCell>
                                    <TableCell>{new Date(trimestre.dateFin).toLocaleDateString('en-GB')}</TableCell>
                                    <TableCell>{trimestre.anneeScolaire ? trimestre.anneeScolaire.nom : 'N/A'}</TableCell>
                                    <TableCell>
                                        <IconButton component={Link} to={`/trimestre/${trimestre.id}`}>
                                            <RemoveRedEyeOutlinedIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    );
};

export default AfficherTrimestres;
