import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const MaybeShowSideBar = ({ children }) => {
    const location = useLocation();
    const [showSideBar, setShowSidebar] = useState(false);

    useEffect(() => {
        const pathsWithoutSidebar = [
            '/login',
            '/accueilresponsable',
            '/rapporttrimestriel',
            '/'
        ];

        const matchesPath = pathsWithoutSidebar.includes(location.pathname) ||
            /^\/resultatsanneescolaire\/[^/]+$/.test(location.pathname) ||
            /^\/rapporttrimestriel\/[^/]+$/.test(location.pathname);

        setShowSidebar(!matchesPath);
    }, [location]);

    return (
        <div>{showSideBar && children}</div>
    );
};

export default MaybeShowSideBar;
