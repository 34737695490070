import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Snackbar, Alert, Button, Paper, Typography, Grid, Box, IconButton, Card, CardHeader, CardContent,  } from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon, Person as PersonIcon } from '@mui/icons-material';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

const UserById = () => {
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserById = async () => {
      try {
        const response = await axios.get(`${apiUrl}/user/${id}`);
        setUser(response.data);
      } catch (error) {
        console.error('Error fetching responsible data:', error);
      }
    };

    fetchUserById();
  }, [id]);

  const handleEdit = () => {
    navigate(`/user/${id}/edit`);
  };

  const handleDelete = async () => {
    const confirmDelete = window.confirm('Êtes-vous sûr·e de vouloir supprimer ce responsable?');
    
    if (confirmDelete) {
      try {
        await axios.delete(`${apiUrl}/user/${id}`);
        console.log("Deleted successfully");
        setSnackbarOpen(true);
      } catch (error) {
        console.error('Error deleting user:', error);
      }
    }
  };

  if (!user) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
              <Card>
                <CardHeader avatar={<PersonIcon />} title="Responsable" />
                <CardContent>
                  <Typography variant="body2" color="textSecondary">{user && `${user.nom} ${user.prenom}`}</Typography>
                  <Typography variant='body2' color="textSecondary" >Telephone: {user.telephone}</Typography>
                  <Typography variant='body2' color="textSecondary" >Telephone Secondaire: {user.telephoneSecondaire}</Typography>
                </CardContent>
              </Card>
        </Grid>
      </Grid>

      {/* ACTION BUTTONS */}
      <div style={{ position: 'fixed', bottom: 20, left: 225, right: 20, display: 'flex', justifyContent: 'center', zIndex: 1000 }}>
        <Box mt={2} mb={2}>
          <Grid container spacing={2}>
            <Grid item>
              <Button variant="contained" color='warning' startIcon={<EditIcon />} onClick={handleEdit}>
                Modifier le responsable
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="error" startIcon={<DeleteIcon />} onClick={handleDelete}>
                Supprimer le responsable
              </Button>
            </Grid>
          </Grid>
        </Box>
      </div>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={() => {
          setSnackbarOpen(false);
          navigate('/user/responsable/all');
        }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} variant='filled' severity="success">
          Responsable supprimé·e avec succès
        </Alert>
      </Snackbar>
    </div>
  );
};

export default UserById;
