import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Alert, Button, Snackbar, Typography, Paper } from '@mui/material';
import { BarChart, PieChart } from '@mui/x-charts';
import { Link } from 'react-router-dom';
import '../styles/Dashboard.scss';

const apiUrl = process.env.REACT_APP_API_URL;

function Dashboard() {
    const [pieChartNbBeneficiaire, setPieChartNbBeneficiaire] = useState([]);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [pieChartNbEntities, setPieChartNbEntities] = useState([]);
    const [pieChartNbResponsables, setPieChartNbResponsable] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch total boys and girls from API
                const totalBoysResponse = await axios.get(`${apiUrl}/entity/all/totalboys`);
                const totalGirlsResponse = await axios.get(`${apiUrl}/entity/all/totalgirls`);
                const totalDarTalibasResponse = await axios.get(`${apiUrl}/entity/dartaliba/all/count`);
                const totalAssociationsResponse = await axios.get(`${apiUrl}/entity/association/all/count`);
                const totalResponsablesResponse = await axios.get(`${apiUrl}/user/responsable/all/count`);

                // Get data from responses and ensure they are numbers
                const totalBoys = parseInt(totalBoysResponse.data.totalBoys, 10);
                const totalGirls = parseInt(totalGirlsResponse.data.totalGirls, 10);
                const totalBeneficiaire = totalGirls + totalBoys;
                const totalDarTalibas = parseInt(totalDarTalibasResponse.data, 10);
                const totalAssociations = parseInt(totalAssociationsResponse.data, 10);
                const totalResponsables = parseInt(totalResponsablesResponse.data, 10);
                
                // Prepare data for the pie chart
                const nbTotalBeneficiaire = [
                    { value: totalBoys, label: 'Bénéficiaires garçons' },
                    { value: totalGirls, label: 'Bénéficiaires filles' },
                    { value: totalBeneficiaire, label: 'Total Bénéficiaires'},
                ];
                // Update state with pie chart data
                setPieChartNbBeneficiaire(nbTotalBeneficiaire);
                
                const nbTotalEntities = [
                    { value: totalDarTalibas, label: 'Total Dar Talibas' },
                    { value: totalAssociations, label: 'Total Associations' },
                ];
                setPieChartNbEntities(nbTotalEntities);

                const nbTotalResponsable = [
                    { value: totalResponsables, label: 'Total Responsables', color: '#f0ab8d' },
                ];
                setPieChartNbResponsable(nbTotalResponsable);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        // Fetch data when component mounts
        fetchData();
    }, []);

    const resetResultatAnneeScolaire = async () => {
        const confirmReset = window.confirm('Êtes-vous sûr·e de vouloir réinitialiser l\'accès pour les responsables ? Cette action va leur autoriser de soumettre les rapports scolaires relatifs à leur association/dar taliba.');
        if (confirmReset) {
            try {
                await axios.put(`${apiUrl}/entity/resultatanneescolaire/reset/all`);
                setOpenSnackbar(true);
            } catch (error) {
                console.error('Error resetting rapport annee scolaire:', error);
                alert('Échec de la réinitialisation');
            }
        }
    };

    const resetRapportTrimestriel = async () => {
        const confirmReset = window.confirm('Êtes-vous sûr·e de vouloir réinitialiser l\'accès pour les responsables ? Cette action va leur autoriser de soumettre les rapports trimestriels relatifs à leur association/dar taliba.');
        if (confirmReset) {
            try {
                await axios.put(`${apiUrl}/entity/rapporttrimestriel/reset/all`);
                setOpenSnackbar(true);
            } catch (error) {
                console.error('Error resetting rapport trimestriel:', error);
                alert('Échec de la réinitialisation');
            }
        }
    };

    // Check if data is available before rendering the PieChart components
    const isDataAvailable = pieChartNbBeneficiaire.length > 0 && pieChartNbEntities.length > 0 && pieChartNbResponsables.length > 0;

    return (
        <div>
            <div className='dashboardTitle'>
                <Typography variant='h6' style={{ fontWeight: '700', color: '#1F384C' }}>
                    Dashboard
                </Typography>
                <Paper elevation={5} className='addButtons' sx={{ padding: 2, margin: 2 }}>
                    <React.Fragment>
                        <Button
                            component={Link}
                            to="/entity/new"
                            variant="contained"
                            color="primary"
                            sx={{ mr: 2 }}
                        >
                            Ajouter une Dar Taliba/Association
                        </Button>
                        <Button
                            component={Link}
                            to="/user/new"
                            variant="contained"
                            color="primary"
                            sx={{ mr: 2 }}
                        >
                            Ajouter un.e Responsable
                        </Button>
                        <Button
                            component={Link}
                            to="/anneescolaire/new"
                            variant="contained"
                            color="primary"
                            sx={{ mr: 2 }}
                        >
                            Ajouter une Année Scolaire
                        </Button>
                        <Button
                            component={Link}
                            to="/trimestre/new"
                            variant="contained"
                            color="primary"
                            sx={{ mr: 2 }}
                        >
                            Ajouter un trimestre
                        </Button>
                        <Button
                            variant='contained'
                            sx={{ mt: 2, mr: 2 }}
                            onClick={() => resetResultatAnneeScolaire()}
                        >
                            Réinitialiser l'accès pour l'envoi des rapports de l'année scolaire 
                        </Button>
                        <Button
                            variant='contained'
                            sx={{ mt: 2 }}
                            onClick={() => resetRapportTrimestriel()}
                        >
                            Réinitialiser l'accès pour l'envoi des rapports trimestriels
                        </Button>
                        <Button
                            component={Link}
                            to="/product/all"
                            variant='contained'
                            sx={{ mt: 2 }}
                        >
                            Liste des produits
                        </Button>
                    </React.Fragment>
                </Paper>
            </div>
            
            {/* PIES */}
            {isDataAvailable && (
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <Paper elevation={5} sx={{ padding: 2, margin: 2, width: '45%' }}> {/* Adjust width as needed */}
                    <PieChart height={125}
                        series={[
                            {
                                data: [
                                    { value: pieChartNbBeneficiaire[0].value, label: pieChartNbBeneficiaire[0].label, color: '#00bbf0' },
                                    { value: pieChartNbBeneficiaire[1].value, label: pieChartNbBeneficiaire[1].label, color: '#f4aeba' },
                                ],
                                highlightScope: { faded: 'global', highlighted: 'item' },
                                faded: { innerRadius: 10, additionalRadius: -30, color: 'gray' },
                            },
                            {
                                // Outer ring for the total beneficiaries
                                data: [{ value: pieChartNbBeneficiaire[2].value, label: pieChartNbBeneficiaire[2].label, color: '#5c5470' }],
                                innerRadius: 50, // Adjust the inner radius to fit the outer ring
                                outerRadius: 60, // Set the outer radius as desired
                                highlightScope: { faded: 'global', highlighted: 'item' },
                                faded: { innerRadius: 65, additionalRadius: -20, color: 'gray' },
                            },
                        ]}
                    />
                </Paper>
                {/* Pie chart for total Dar Talibas and Associations with an outer ring for total Responsables */}
                <Paper elevation={5} sx={{ padding: 2, margin: 2, width: '45%' }}> {/* Adjust width as needed */}
                    <PieChart height={125}
                        series={[
                            {
                                data: [
                                    { value: pieChartNbEntities[0].value, label: pieChartNbEntities[0].label, color: '#d55b3e' },
                                    { value: pieChartNbEntities[1].value, label: pieChartNbEntities[1].label, color: '#f8da5b' },
                                ],
                                highlightScope: { faded: 'global', highlighted: 'item' },
                                faded: { innerRadius: 10, additionalRadius: -30, color: 'gray' },
                            },
                            // Outer ring for total Responsables
                            {
                                data: pieChartNbResponsables,
                                innerRadius: 50,
                                outerRadius: 60,
                                highlightScope: { faded: 'global', highlighted: 'item' },
                                faded: { innerRadius: 65, additionalRadius: -20, color: 'gray' },
                            },
                    ]}
                    />
                </Paper>
            </div>
            )}
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                {/* add chart here for distribution finale */}
            </div>

            {/* SNACKBAR */}
            <Snackbar
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={() => setOpenSnackbar(false)}
                >
                <Alert onClose={() => setOpenSnackbar(false)} severity='success' variant='filled'>
                    Réinitialisation réussie.
                </Alert>
            </Snackbar>
        </div>
    );
}

export default Dashboard;
