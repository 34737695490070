import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Alert,
    Button,
    Snackbar,
} from '@mui/material'

const apiUrl = process.env.REACT_APP_API_URL;

const AfficherAnneeScolaireById = () => {
    const [anneeScolaire, setAnneeScolaire] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => { 
        const fetchAnneeScolaireById = async () => {
            try {
                const response = await axios.get(`${apiUrl}/anneescolaire/${id}`);
                setAnneeScolaire(response.data);
            } catch (error) {
                console.log("Error fetching annee scolaire by id: ", error);
            }
        };

        fetchAnneeScolaireById();
    }, [id]);

    const handleDelete = async () => {
        const confirmDelete = window.confirm('Êtes-vous sûr·e de vouloir supprimer cette année scolaire ?');
        if(confirmDelete) {
            try {
                await axios.delete(`${apiUrl}/anneescolaire/${id}`);
                console.log('Année scolaire deleted successfuly');
                setSnackbarOpen(true); // Open Snackbar after successful deletion
            } catch (error) {
                console.error("Error deleting annee scolaire: ", error);
            }
        }
    };

    const handleEdit = async () => {
        navigate(`/anneescolaire/${id}/edit`);
    };
    // Check if anneeScolaire is null before rendering its properties
    if (!anneeScolaire) {
        return <div>Loading...</div>; // or any other loading indicator
    }

    return (
        <div>
            <h2>{anneeScolaire.nom}</h2>
            <p>Date Début: {new Date(anneeScolaire.anneeDebut).toLocaleDateString('en-GB')}</p>
            <p>Date Fin: {new Date(anneeScolaire.anneeFin).toLocaleDateString('en-GB')}</p>
            <Button onClick={handleDelete} variant="contained" color="error" startIcon={<DeleteIcon />}>Supprimer l'année scolaire</Button>
            <Button onClick={handleEdit} variant="contained" color="warning">Modifier l'année scolaire</Button>
            {/* <Button onClick={handleEdit} variant="contained" color="warning" >Modifier l'année scolaire</Button> */}

            {/* Snackbar component */}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={2000}
                onClose={() => {
                    setSnackbarOpen(false);
                    navigate('/anneeacademique/all');
                }}
            >
                <Alert onClose={() => setSnackbarOpen(false)} severity="success" variant='filled'>
                    Année scolaire supprimée avec succès
                </Alert>
            </Snackbar>
        </div>
    );
};

export default AfficherAnneeScolaireById;
