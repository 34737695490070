import * as React from 'react';

function Header() {

  return (
    <div className='header'>
      
    </div>
  );
}
export default Header;