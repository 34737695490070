import React, { useState, useEffect } from 'react';
import { Stepper, Step, StepLabel, Button, Typography, Container, IconButton, Grid, TextField, MenuItem, Alert, Snackbar } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ConsommationProduct from '../../components/formComponents/formComponentsRapportTrimestriel/ConsommationProduct';
import axios from 'axios';
import '../../styles/RapportTrimestriel.scss';

const apiUrl = process.env.REACT_APP_API_URL;

const RapportTrimestriel = () => {
  const { entityId } = useParams();
  const [activeStep, setActiveStep] = useState(0);
  const [anneeScolaires, setAnneeScolaires] = useState([]);
  const [trimestres, setTrimestres] = useState([]);
  const [entity, setEntity] = useState([]);
  const [prefectures, setPrefectures] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [regions, setRegions] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [rapportTrimestrielId, setRapportTrimestrielId] = useState(null);
  const [academiesRegionales, setAcademiesRegionales] = useState([]);
  const navigate = useNavigate();
  const [consommationProducts, setConsommationProducts] = useState([]);
  const [formValues, setFormValues] = useState({
    nbBeneficiaireFeminin: '',
    nbBeneficiaireMasculin: '',
    nbExemptionCotisationMensuel: '',
    nbBeneficiaireSemestreFeminin: '',
    nbBeneficiaireSemestreMasculin: '',
    nbFemininQuitterCentre: '',
    nbMasculinQuitterCentre: '',
    raisonQuitterCentre: '',
    remarqueIndemniteQuitterCentre: '',
    nbTasaroubFeminin: '',
    nbTasaroubMasculin: '',
    raisonTasaroub: '',
    remarqueTasaroub: '',
    nbHadrMadrassiFeminin: '',
    nbHadrMadrassiMasculin: '',
    raisonHadrMadrassi: '',
    remarqueHadrMadrassi: '',
    coteOrganisationnelCentre: '',
    coteAlimentaireCentre: '',
    typeCasEnregistreSante: '',
    nbCasEnregistreSante: '',
    typeTraitementCasEnregistreSante: '',
    dispensaire: '',
    EntityId: entityId,
    TrimestreId: '',
    anneeScolaireId: '',
  });
  
  const { t, i18n} = useTranslation();
  const switchLanguage = (language) => {
    i18n.changeLanguage(language);
  }

  const steps = [t('basicInfos'), t('beneficiaries'), t('beneficiariesAcademicInfos'), t('health'), t('Consumption of Food/Hygiene Products')];


  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch Annee Scolaires
        const anneeScolairesResponse = await axios.get(`${apiUrl}/anneescolaire/all`);
        setAnneeScolaires(anneeScolairesResponse.data);
        // Fetch Trimestres
        const trimestresResponse = await axios.get(`${apiUrl}/trimestre/all`);
        setTrimestres(trimestresResponse.data);
        // Fetch region data
        const regionsResponse = await axios.get(`${apiUrl}/region/all`);
        setRegions(regionsResponse.data);
        // Fetch Academies Regionales
        const academiesRegionalesResponse = await axios.get(`${apiUrl}/academieregionale/all`);
        setAcademiesRegionales(academiesRegionalesResponse.data);
        // Fetch prefecture data
        const prefecturesResponse = await axios.get(`${apiUrl}/prefecture/all`);
        setPrefectures(prefecturesResponse.data);
        // Fetch province data
        const provincesResponse = await axios.get(`${apiUrl}/province/all`);
        setProvinces(provincesResponse.data);

        // Fetch selected entity data
        const entityResponse = await axios.get(`${apiUrl}/entity/${entityId}`);
        setEntity(entityResponse.data);
        console.log(entity);
        // Fetch logged-in user data
        const sessionResponse = await axios.get(`${apiUrl}/session`);
        if (sessionResponse.data.success) {
        } else {
          navigate('/login');
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          navigate('/login'); // Redirect to login page for unauthorized access
        } else {
          console.error('Error fetching data:', error);
        }
      }
    };
  
    fetchData();
  }, [navigate]);
  

  const handleNext = () => {
    if (!validateForm()) {
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const totalSteps = () => {
    return steps.length;
  };

  const handleHomeClick = () => {
    navigate('/accueilresponsable');
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues(prevFormValues => ({
      ...prevFormValues,
      [name]: value
    }));
  };
  
  const handleSubmit = async () => {
    if (activeStep === 3) {
        // Validate the form for step 3 (Santé)
        if (!validateForm()) {
            setSnackbarOpen(true);
            return;
        }
        
        try {
            const response = await axios.post(`${apiUrl}/rapporttrimestriel/new`, formValues);
            console.log('Rapport Trimestriel submitted successfully:', response.data);

            const newRapportTrimestrielId = response.data.id;
            setRapportTrimestrielId(newRapportTrimestrielId);

          } catch (error) {
            console.error('Error submitting RapportDetaille:', error);
        }
    }

    if (activeStep === 4) {
      try {
          // Create ConsommationProduct for each product
          const consommationProductsData = consommationProducts.map(product => ({
              RapportTrimestrielId: rapportTrimestrielId,  // Updated field name
              ProductId: product.productId,
              quantityReceived: product.quantityReceived,
              totalRecu: product.totalRecu,
              totalParticipationBanqueAlimentaire: product.totalParticipationBanqueAlimentaire
          }));
  
          await Promise.all(consommationProductsData.map(data =>
              axios.post(`${apiUrl}/consommationproduct/new`, data)
          ));
  
          console.log('ConsommationProducts submitted successfully');
          // Move to the next step or finish
          handleNext();
      } catch (error) {
          console.error('Error submitting ConsommationProducts:', error);
      }
  } else {
      handleNext();
  }
  
  };

  // Function to get location name by ID
  const getLocationName = (locationId, locationType) => {
    if (locationType === 'Province') {
        const province = provinces.find(province => province.id === locationId);
        return province ? province.nom : '';
    } else if (locationType === 'Prefecture') {
        const prefecture = prefectures.find(prefecture => prefecture.id === locationId);
        return prefecture ? prefecture.nom : '';
    }
    return '';
  };
  
  // Function to get region name
  const getRegionName = (locationId, locationType) => {
    if (locationType === 'Province') {
        const province = provinces.find(province => province.id === locationId);
        if (province) {
            const regionId = province.RegionId;
            const region = regions.find(region => region.id === regionId);
            return region ? region.nom : '';
        }
    } else if (locationType === 'Prefecture') {
        const prefecture = prefectures.find(prefecture => prefecture.id === locationId);
        if (prefecture) {
            const regionId = prefecture.RegionId;
            const region = regions.find(region => region.id === regionId);
            return region ? region.nom : '';
        }
    }
    return '';
  };
  // Function to get Academie regionale name
  const getAcademieRegionalName = (locationId, locationType) => {
    let regionId = '';
  
    if (locationType === 'Province') {
      const province = provinces.find(province => province.id === locationId);
      if (province) {
        regionId = province.RegionId;
      }
    } else if (locationType === 'Prefecture') {
      const prefecture = prefectures.find(prefecture => prefecture.id === locationId);
      if (prefecture) {
        regionId = prefecture.RegionId;
      }
    }
    const academieRegionale = academiesRegionales.find(academie => academie.RegionId === regionId);
    return academieRegionale ? academieRegionale.nom : '';
  };

  const validateForm = () => {
    // Define the required fields for each step of the form
    const requiredFieldsByStep = {
        0: ['anneeScolaireId', 'TrimestreId'], // Required fields for step 0
        1: ['nbBeneficiaireFeminin', 'nbBeneficiaireMasculin', 'nbExemptionCotisationMensuel', 'nbBeneficiaireSemestreFeminin', 'nbBeneficiaireSemestreMasculin'], // Required fields for step 1
        2: ['nbFemininQuitterCentre', 'nbMasculinQuitterCentre', 'raisonQuitterCentre', 'remarqueIndemniteQuitterCentre', 'nbTasaroubFeminin', 'nbTasaroubMasculin', 'raisonTasaroub', 'remarqueTasaroub', 'nbHadrMadrassiFeminin', 'nbHadrMadrassiMasculin', 'raisonHadrMadrassi', 'remarqueHadrMadrassi', 'coteOrganisationnelCentre', 'coteAlimentaireCentre'], // Required fields for step 2
        3: ['typeCasEnregistreSante', 'nbCasEnregistreSante', 'typeTraitementCasEnregistreSante', 'dispensaire'], // Required fields for step 3
        // Step 4 has no required fields in this case
    };

    // If it's the last step, skip validation
    if (activeStep === 4) {
      return true;
    }

    // Get the list of required fields for the current step
    const requiredFields = requiredFieldsByStep[activeStep];
    
    // Check if all required fields are filled in
    for (const field of requiredFields) {
        if (!formValues[field]) {
            // If any required field is missing, open the snackbar and return false
            setSnackbarOpen(true);
            return false;
        }
    }
    
    // Return true if all required fields are filled in
    return true;
  };

  return (
    <Container>
      <div className='top-header-rapports'>
        <div className='homeButton'>
          <IconButton onClick={handleHomeClick}>
            <HomeIcon/>
          </IconButton>
        </div>
        <div className='language-selector'>
            <span className="language-option" onClick={() => switchLanguage('fr')}>Français</span> | 
            <span className="language-option" onClick={() => switchLanguage('ar')}>العربية</span>
        </div>
      </div>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography>Términé</Typography>
          </div>
        ) : (
          <div className='form'>
            {activeStep === 0 && (
              <form>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                   <TextField
                      name="nom"
                      label={t("name")}
                      fullWidth
                      value={entity.nom || ''}
                      disabled
                   />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="region"
                      label={t("region")}
                      fullWidth
                      value={getRegionName(entity.locationId, entity.locationType) || ''}
                      onChange={handleInputChange}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                  <TextField
                    name="provinceOrPrefecture" 
                    label={t("prefectureOrProvince")}
                    fullWidth
                    value={getLocationName(entity.locationId, entity.locationType) || ''} 
                    disabled
                  />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="academieRegionale"
                      label={t("regionalAcademy")}
                      fullWidth
                      value={getAcademieRegionalName(entity.locationId, entity.locationType) || ''}
                      onChange={handleInputChange}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="adresse"
                      label={t("address")}
                      fullWidth
                      value={entity.adresse || ''}
                      onChange={handleInputChange}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="telephone"
                      label={t("phoneNumber")}
                      fullWidth
                      value={entity.telephone || ''}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="telephoneFixe"
                      label={t("Numero de telephone fixe")}
                      fullWidth
                      value={entity.telephoneFixe || ''}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      select
                        name="anneeScolaireId"
                        label={t("schoolYear")}
                        type='number'
                        fullWidth
                        value={formValues.anneeScolaireId}
                        onChange={handleInputChange}
                    >
                    {anneeScolaires.map(anneeScolaire => (
                      <MenuItem key = {anneeScolaire.id} value = {anneeScolaire.id}>
                        {anneeScolaire.nom}
                      </MenuItem>
                    ))}
                    </TextField>                  
                  </Grid>
                  {formValues.anneeScolaireId && (
                    <Grid item xs={12} sm={6}>
                    <TextField
                      select
                      name="TrimestreId"
                      label={t("quarter")}
                      fullWidth
                      value={formValues.TrimestreId}
                      onChange={handleInputChange}
                    >
                      {trimestres.filter(trimestre => trimestre.AnneeScolaireId === formValues.anneeScolaireId).map(trimestre => (
                        <MenuItem key={trimestre.id} value={trimestre.id}>
                          {t(trimestre.nom)}
                        </MenuItem>
                      ))}
                      </TextField>
                  </Grid>
                  )}
               </Grid>
              </form>
            )}
            {activeStep === 1 && (
              <form>
                <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextField
                      name="nbBeneficiaireFeminin"
                      label={t("numberOfFemaleBeneficiaries")}
                      type='number'
                      fullWidth
                      value={formValues.nbBeneficiaireFeminin}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="nbBeneficiaireMasculin"
                      label={t("numberOfMaleBeneficiaries")}
                      type='number'
                      fullWidth
                      value={formValues.nbBeneficiaireMasculin}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="nbExemptionCotisationMensuel"
                      label={t("number of monthly contribution exemptions")}
                      type='number'
                      fullWidth
                      value={formValues.nbExemptionCotisationMensuel}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="nbBeneficiaireSemestreFeminin"
                      label={t("number of female beneficiaries during this quarter")}
                      type='number'
                      fullWidth
                      value={formValues.nbBeneficiaireSemestreFeminin}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="nbBeneficiaireSemestreMasculin"
                      label={t("number of male beneficiaries during this quarter")}
                      type='number'
                      fullWidth
                      value={formValues.nbBeneficiaireSemestreMasculin}
                      onChange={handleInputChange}
                    />
                  </Grid>
                </Grid>
              </form>
            )}
            {activeStep === 2 && (
              <form>
                <Grid container spacing={2}>
                 <Grid item xs={12} sm={3}>
                    <TextField
                      name="nbFemininQuitterCentre"
                      label={t("number of female departure cases")}
                      type="number"
                      fullWidth
                      value={formValues.nbFemininQuitterCentre}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="nbMasculinQuitterCentre"
                      label={t("number of male departure cases")}
                      type="number"
                      fullWidth
                      value={formValues.nbMasculinQuitterCentre}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="raisonQuitterCentre"
                      label={t("reason for departure")}
                      type="text"
                      fullWidth
                      value={formValues.raisonQuitterCentre}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="remarqueIndemniteQuitterCentre"
                      label={t("remark/Compensation")}
                      type="text"
                      fullWidth
                      value={formValues.remarqueIndemniteQuitterCentre}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="nbTasaroubFeminin"
                      label={t("number of female academic dropout cases")}
                      type="number"
                      fullWidth
                      value={formValues.nbTasaroubFeminin}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="nbTasaroubMasculin"
                      label={t("number of male academic dropout cases")}
                      type="number"
                      fullWidth
                      value={formValues.nbTasaroubMasculin}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="raisonTasaroub"
                      label={t("reason for dropout")}
                      type="text"
                      fullWidth
                      value={formValues.raisonTasaroub}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="remarqueTasaroub"
                      label={t("remark/Compensation")}
                      type="text"
                      fullWidth
                      value={formValues.remarqueTasaroub}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="nbHadrMadrassiFeminin"
                      label={t("number of female school dropout cases")}
                      type="number"
                      fullWidth
                      value={formValues.nbHadrMadrassiFeminin}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="nbHadrMadrassiMasculin"
                      label={t("number of male school dropout cases")}
                      type="number"
                      fullWidth
                      value={formValues.nbHadrMadrassiMasculin}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="raisonHadrMadrassi"
                      label={t("reasons")}
                      type="text"
                      fullWidth
                      value={formValues.raisonHadrMadrassi}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="remarqueHadrMadrassi"
                      label={t("remark/Compensation")}
                      type="text"
                      fullWidth
                      value={formValues.remarqueHadrMadrassi}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="coteOrganisationnelCentre"
                      label={t("organizational aspect of the entity")}
                      type="text"
                      fullWidth
                      value={formValues.coteOrganisationnelCentre}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="coteAlimentaireCentre"
                      label={t("nutrition aspect of the entity: Contribution of the Food Bank and other partners")}
                      type="text"
                      fullWidth
                      value={formValues.coteAlimentaireCentre}
                      onChange={handleInputChange}
                    />
                  </Grid>
                </Grid>
              </form>
            )}     
            {activeStep === 3 && (
              <form>
                <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                    <TextField
                      name="typeCasEnregistreSante"
                      label={t("type of registered cases")}
                      type="text"
                      fullWidth
                      value={formValues.typeCasEnregistreSante}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="nbCasEnregistreSante"
                      label={t("number of registered cases")}
                      type="number"
                      fullWidth
                      value={formValues.nbCasEnregistreSante}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="typeTraitementCasEnregistreSante"
                      label={t("type of treatment")}
                      type="text"
                      fullWidth
                      value={formValues.typeTraitementCasEnregistreSante}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="dispensaire"
                      label={t("nearby health center")}
                      type="text"
                      fullWidth
                      value={formValues.dispensaire}
                      onChange={handleInputChange}
                    />
                  </Grid>
                </Grid>
              </form>
            )}
            {activeStep === 4 && (
              <form>
                <ConsommationProduct 
                consommationProducts={consommationProducts}
                setConsommationProducts={setConsommationProducts}
                rapportTrimestrielId = {rapportTrimestrielId} // Example ID, adjust as needed
            />
              </form>
            )}

            <div className='navButtons'>
              {/* Disable the "Back" button if the user is at the final step */}
              <Button disabled={activeStep === 0 || activeStep === totalSteps() - 1} onClick={handleBack}>
                {t('retour')}
              </Button>
              <Button variant="contained" onClick={handleSubmit}>
                {activeStep === steps.length - 1 ? t('terminer') : t('suivant')}
              </Button>
            </div>

          </div>
        )}
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity='error' variant='filled'>
        {t('please fill out all the fields !')}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={() => {
          setOpenSnackbar(false);
        }}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity="success" variant='filled'>
            {t('Rapport detaillé ajouté avec succès.')}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default RapportTrimestriel;