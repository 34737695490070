import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Card from '@mui/material/Card';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import axios from 'axios';
import '../styles/AccueilResponsable.scss';
import { useTranslation } from 'react-i18next';

const apiUrl = process.env.REACT_APP_API_URL;

function AccueilResponsable() {
    const [loggedUser, setLoggedUser] = useState(null);
    const [entitiesData, setEntitiesData] = useState([]);
    const [selectedEntity, setSelectedEntity] = useState('');

    const { t, i18n } = useTranslation();

    const switchLanguage = (language) => {
        i18n.changeLanguage(language);
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const sessionResponse = await axios.get(`${apiUrl}/session`);
                if (sessionResponse.data.success) {
                    setLoggedUser(sessionResponse.data.user);
                    // Fetch the entity data for the logged-in user
                    const entitiesResponse = await axios.get(`${apiUrl}/entity/all/user/${sessionResponse.data.user.id}`);
                    if (entitiesResponse.data.success) {
                        setEntitiesData(entitiesResponse.data.entities || []); // Ensure entitiesData is an array
                    }
                }
            } catch (error) {
                console.error('Error fetching data', error);
            }
        };

        fetchData();
    }, []);

    const handleEntityChange = (event) => {
        const entityId = event.target.value;
        setSelectedEntity(entityId);
    };

    // Find the selected entity based on the selectedEntity ID
    const selectedEntityData = entitiesData.find(entity => entity.id === selectedEntity);

    return (
        <div className="accueilResponsableContainer" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <div>
                <div className='language-selector'>
                    <span className="language-option" onClick={() => switchLanguage('fr')}>Français</span> | 
                    <span className="language-option" onClick={() => switchLanguage('ar')}>العربية</span>
                </div>
                <Card variant="outlined">
                    <CardContent>
                        <div style={{ textAlign: 'center' }}>
                            <Typography variant="body1" component="p">
                                {t('Please fill out the following reports')}
                            </Typography>
                        </div>
                        <div style={{ marginTop: '1rem', textAlign: 'center' }}>
                            <FormControl variant="outlined" style={{ minWidth: 200 }}>
                                <InputLabel id="entity-select-label">{t('Select Entity')}</InputLabel>
                                <Select
                                    labelId="entity-select-label"
                                    id="entity-select"
                                    value={selectedEntity}
                                    onChange={handleEntityChange}
                                    label={t('Select Entity')}
                                >
                                    {entitiesData.map((entity) => (
                                        <MenuItem key={entity.id} value={entity.id}>
                                            {entity.nom}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        {selectedEntityData && (
                            <div style={{ marginTop: '1rem', textAlign: 'center' }}>
                                <Button
                                    component={Link}
                                    to={`/rapporttrimestriel/${selectedEntity}`}
                                    variant="contained" color="primary"
                                    style={{ marginRight: '1rem' }}
                                    startIcon={<BorderColorIcon />}
                                    disabled={selectedEntityData.isRapportTrimestrielSubmitted} // Disable button if isRapportDetailleSubmitted is true in selectedEntityData
                                >
                                    {t('Trimestrial report')}
                                </Button>
                                <Button
                                    component={Link}
                                    to={`/resultatsanneescolaire/${selectedEntity}`}
                                    variant="contained"
                                    color="primary"
                                    startIcon={<BorderColorIcon />}
                                    disabled={selectedEntityData.isResultatsAnneeScolaireSubmitted}
                                >
                                    {t('School year results')}
                                </Button>

                            </div>
                        )}
                    </CardContent>
                </Card>
            </div>
        </div>
    );
}

export default AccueilResponsable;
