import axios from 'axios';
import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { Snackbar, Alert } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { useNavigate } from 'react-router-dom';

const steps = ['Informations de base', 'Préfecture/Province/Adresse', 'Responsable'];

export default function CreateEntity() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [regions, setRegions] = useState([]);
  const [responsables, setResponsables] = useState([]);
  const [prefectures, setPrefectures] = useState([]); 
  const [provinces, setProvinces] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(null);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [skipped, setSkipped] = React.useState(new Set());
  const navigate = useNavigate();
  const [formData, setFormData] = React.useState({
    nom: "",
    typeEntity: "",
    numeroAutorisation: "",
    telephone: "",
    telephoneFixe: "",
    adresse: "",
    totalGirls: 0,
    totalBoys: 0,
    totalEtablissements: 0,
    region: '', // Added initial value for region
    locationType: "",
    locationId: '', // Added initial value for locationId
    locations: [],
    userId: '',
  });

  const apiUrl = process.env.REACT_APP_API_URL;

  const totalSteps = () => {
    return steps.length;
  };

  useEffect(() => {
    const fetchRegions = async () => {
      try {
        const regionResponse = await axios.get(`${apiUrl}/region/all`);
        setRegions(regionResponse.data)
      } catch (error) {
        console.error('Error fetching regions:', error);
      }
    };
    
    const fetchResponsables = async () => {
      try {
        const responsableResponse = await axios.get(`${apiUrl}/user/responsable/all`);
        setResponsables(responsableResponse.data);
      } catch (error) {
        console.log('Error fetching responsables:', error);
      }
    }

    fetchRegions();
    fetchResponsables();
  }, []);

  const handleSubmit = async () => {
    // Validate the form

    if (!validateForm()) {
        // Display an error message or handle the error in some way
        setSnackbarMessage('Veuillez remplir tout les champs');
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
        return;
    }

    if (activeStep === totalSteps() - 1) {
        try {
            // Gather all form data
            const dataToSend = {
                nom: formData.nom,
                typeEntity: formData.typeEntity,
                numeroAutorisation: formData.numeroAutorisation,
                telephone: formData.telephone,
                telephoneFixe: formData.telephoneFixe,
                adresse: formData.adresse,
                totalGirls: formData.totalGirls,
                totalBoys: formData.totalBoys,
                totalEtablissements: formData.totalEtablissements,
                locationType: formData.locationType,
                locationId: formData.locationId,
                UserId: formData.userId
            };

            // Make a POST request to submit the data
            const response = await axios.post(`${apiUrl}/entity/new`, dataToSend);

            // Handle success response
            console.log('Data submitted successfully:', response.data);
            setSnackbarMessage('Entité ajoutée avec succès');
            setSnackbarSeverity('success');
            setOpenSnackbar(true);
            // Navigate to dashboard after 2 seconds
            setTimeout(() => {
              navigate('/dashboard');
            }, 2000);
            // Reset the active step to the first step
        } catch (error) {
            // Handle errors
            console.error('Error submitting data:', error);
        }
    }
    handleNext();
};

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  
    if (name === 'region') {
      try {
        const prefecturesResponse = await axios.get(`${apiUrl}/region/${value}/prefectures`);
        const provincesResponse = await axios.get(`${apiUrl}/region/${value}/provinces`);
        setPrefectures(prefecturesResponse.data);
        setProvinces(provincesResponse.data);
        // Reset locationType when region changes
        setFormData((prevFormData) => ({
          ...prevFormData,
          locationType: '',
          locationId: ''
        }));
      } catch (error) {
        console.error('Error fetching prefectures and provinces:', error);
      }
    } else if (name === 'locationType') {
      // Update locationType state when radio button changes
      setFormData((prevFormData) => ({
        ...prevFormData,
        locationType: value,
        locationId: ''
      }));
    } 
  };
  
  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const validateForm = () => {
    // Define the required fields for each step of the form
    const requiredFieldsByStep = {
        0: ['nom', 'typeEntity', 'telephone', 'telephoneFixe', 'totalGirls', 'totalBoys', 'totalEtablissements'],
        1: ['region', 'locationType', 'locationId', 'adresse'],
        2: ['userId']
    };

    // Get the required fields for the current step
    const requiredFields = requiredFieldsByStep[activeStep];

    // Check if all required fields are filled in
    for (const field of requiredFields) {
        const value = formData[field];
        // Check if the value is empty or undefined
        if (value === null || value === undefined || value === '') {
            return false;
        }
    }
    // All required fields are filled in
    return true;
};

  return (
    <div>
    <Box sx={{ width: '100%'}}>
      <Typography variant="h6" mb={3}>
        Nouvelle entité
      </Typography>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        //all steps completed fragment
        <React.Fragment>
          
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Box sx={{ display: 'flex', flexDirection: 'row', mt: 5 }}>
          {activeStep === 0 && (
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label="Nom"
                  name="nom"
                  value={formData.nom}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth margin='normal'>
                  <InputLabel>DarTaliba/Association</InputLabel>
                  <Select
                    value={formData.typeEntity}
                    onChange={handleChange}
                    name="typeEntity"
                  >
                    <MenuItem value="DarTaliba">DarTaliba</MenuItem>
                    <MenuItem value="Association">Association</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Telephone"
                  name="telephone"
                  value={formData.telephone}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Telephone Fixe"
                  name="telephoneFixe"
                  value={formData.telephoneFixe}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Nombre total de filles"
                  name="totalGirls"
                  value={formData.totalGirls}
                  type="Number"
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Nombre total de garçons"
                  name="totalBoys"
                  type="Number"
                  value={formData.totalBoys}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Numero d'autorisation"
                  name="numeroAutorisation"
                  value={formData.numeroAutorisation}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Nombre total d'établissements"
                  name="totalEtablissements"
                  type="Number"
                  value={formData.totalEtablissements}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
            </Grid>
          )}
          {activeStep === 1 && (
            <Grid container spacing={2}>
              <Grid item xs={12} lg={12}>
                <FormControl fullWidth margin='normal'>
                  <InputLabel>Région</InputLabel>
                  <Select
                    label="Région"
                    name="region"
                    value={formData.region}
                    onChange={handleChange}
                    fullWidth
                  >
                    {regions.map(region => (
                      <MenuItem key={region.id} value={region.id}>
                        {region.nom}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              {formData.region && (
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="locationType"
                    value={formData.locationType}
                    onChange={handleChange} // Add this line
                  >
                    <FormControlLabel value="prefecture" control={<Radio />} label="prefecture" />
                    <FormControlLabel value="province" control={<Radio />} label="province" />
                  </RadioGroup>
                </FormControl>
              )}
              </Grid>
              {formData.locationType === 'prefecture' && (
                <Grid item xs={6}>
                  <FormControl fullWidth margin='normal'>
                    <InputLabel>Préfecture</InputLabel>
                    <Select
                      label="Préfecture"
                      name="locationId"
                      value={formData.locationId}
                      onChange={handleChange}
                      fullWidth
                    >
                      {prefectures.map(prefecture => (
                        <MenuItem key={prefecture.id} value={prefecture.id}>
                          {prefecture.nom}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              {formData.locationType === 'province' && (
                <Grid item xs={6}>
                  <FormControl fullWidth margin='normal'>
                    <InputLabel>Province</InputLabel>
                    <Select
                      label="Province"
                      name="locationId"
                      value={formData.locationId}
                      onChange={handleChange}
                      fullWidth
                    >
                      {provinces.map(province => (
                        <MenuItem key={province.id} value={province.id}>
                          {province.nom}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={12}>
                <TextField
                  label="Adresse"
                  name="adresse"
                  value={formData.adresse}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
            </Grid>
          )}
          {activeStep === 2 && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  select
                  label="Responsable"
                  name='userId'
                  value={formData.userId}
                  onChange={(e) => handleChange(e)} // Change this line
                  fullWidth
                  margin="normal"
                 >
                  {responsables.map(user => (
                      <MenuItem key={user.id} value={user.id}>
                        {user.nom} {user.prenom}
                      </MenuItem>
                    ))}
                </TextField>
               </Grid>
            </Grid>
          )}
          </Box>
          <div className='navButtons'>
            {/* retour button */}
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Retour
            </Button>
            
            {/* finish or next button */}
            <Button onClick={handleSubmit}>
              {activeStep === steps.length - 1 ? 'Terminer' : 'Suivant'}
            </Button>
          </div>
        </React.Fragment>
      )}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3500}
        onClose={() => {
          setOpenSnackbar(false);
        }}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarSeverity} variant='filled'>
            {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
    </div>
  );
}