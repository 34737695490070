import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import {
    TableContainer,
    Table,
    Paper,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    IconButton,
    Button,
    Typography,
    Box
} from '@mui/material';

const apiUrl = process.env.REACT_APP_API_URL;

const AfficherAnneesAcademiques = () => {
    const [anneesScolaires, setAnneesScolaires] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${apiUrl}/anneescolaire/all`);
                setAnneesScolaires(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    return (
        <Box>
            <Typography variant='h6'>Liste des années academiques</Typography>
            <Box mt={2}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Année Scolaire</TableCell>
                                <TableCell>Date Début</TableCell>
                                <TableCell>Date Fin</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {anneesScolaires.map((anneeScolaire) => (
                                <TableRow key={anneeScolaire.id} >
                                    <TableCell> {anneeScolaire.nom} </TableCell>
                                    <TableCell>{new Date(anneeScolaire.anneeDebut).toLocaleDateString('en-GB')}</TableCell>
                                    <TableCell>{new Date(anneeScolaire.anneeFin).toLocaleDateString('en-GB')}</TableCell>
                                    <TableCell><IconButton component={Link} to={`/anneescolaire/${anneeScolaire.id}`}><RemoveRedEyeOutlinedIcon/></IconButton></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    )
}

export default AfficherAnneesAcademiques;