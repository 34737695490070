import React, { useEffect, useState } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import { Table, Grid, Paper, TableCell, TableContainer, TableHead, TableRow, TableBody, FormControl, InputLabel, Select, MenuItem, Button, Typography } from '@mui/material';

const apiUrl = process.env.REACT_APP_API_URL;

const AfficherDistributionFinale = () => {
    const [entities, setEntities] = useState([]);
    const [consommationData, setConsommationData] = useState([]);
    const [rapportTrimestrielData, setRapportTrimestrielData] = useState([]);
    const [totalBeneficiaires, setTotalBeneficiaires] = useState(0);
    const [totalValeursDhs, setTotalValeursDhs] = useState(0);
    const [anneeScolaires, setAnneeScolaires] = useState([]);
    const [trimestres, setTrimestres] = useState([]);
    const [selectedAnneeScolaireId, setSelectedAnneeScolaireId] = useState('');
    const [selectedTrimestreId, setSelectedTrimestreId] = useState('');

    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const [entitiesResponse, rapportTrimestrielResponse, consommationResponse, anneeScolairesResponse] = await Promise.all([
                    axios.get(`${apiUrl}/entity/all`),
                    axios.get(`${apiUrl}/rapporttrimestriel/all`),
                    axios.get(`${apiUrl}/consommationproduct/all`),
                    axios.get(`${apiUrl}/anneescolaire/all`)
                ]);

                setEntities(entitiesResponse.data);
                setRapportTrimestrielData(rapportTrimestrielResponse.data);
                setConsommationData(consommationResponse.data);
                setAnneeScolaires(anneeScolairesResponse.data);
                console.log(anneeScolaires);
            } catch (error) {
                console.log('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const fetchTrimestres = async () => {
            try {
                const response = await axios.get(`${apiUrl}/trimestre/byanneescolaire/${selectedAnneeScolaireId}`);
                setTrimestres(response.data);
                setSelectedTrimestreId(''); // Reset selected trimestre when année scolaire changes
            } catch (error) {
                console.log('Error fetching trimestres:', error);
            }
        };
    
        if (selectedAnneeScolaireId) {
            fetchTrimestres();
        } else {
            setTrimestres([]);
        }
    }, [selectedAnneeScolaireId]);
    

    useEffect(() => {
        let totalBeneficiaires = 0;
        let totalValeursDhs = 0;
    
        // Filter and process data based on the selected Trimestre
        entities.forEach(entity => {
            const rapportTrimestriel = rapportTrimestrielData.find(rd => rd.EntityId === entity.id && rd.TrimestreId === selectedTrimestreId);
    
            if (!rapportTrimestriel) {
                return; // Skip if no corresponding rapportTrimestriel
            }
    
            // Filter all consommation products related to this rapportTrimestriel
            const consommations = consommationData.filter(c => c.RapportTrimestrielId === rapportTrimestriel.id);
    
            // Calculate total beneficiaries for each entity
            const totalBeneficiairesEntity = entity.totalBoys + entity.totalGirls;
            totalBeneficiaires += totalBeneficiairesEntity;
    
            // Calculate the total valeurs en DHS for all related consommations
            consommations.forEach(consommation => {
                totalValeursDhs += consommation.totalParticipationBanqueAlimentaire;
            });
        });
    
        // Update state with calculated totals
        setTotalBeneficiaires(totalBeneficiaires);
        setTotalValeursDhs(totalValeursDhs);
    }, [entities, rapportTrimestrielData, consommationData, selectedTrimestreId]);
    
    
    // Function to format values in DHS with thousands separators and two decimal places
    const formatCurrency = (value) => {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'decimal',
            currency: 'MAD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });

        // Format the value and append the "MAD" currency symbol at the end
        const formattedValue = formatter.format(value);
        return `${formattedValue} MAD`;
    };

    const generateExcelData = () => {
        const data = [];
    
         // Add the text before the iteration data
        const selectedTrimestre = trimestres.find(s => s.id === selectedTrimestreId);
        const selectedTrimestreNom = selectedTrimestre ? selectedTrimestre.nom : '';
        
        data.push([`Distribution finale des zones rurales du ${selectedTrimestreNom} valorisées:`, '', '']);
        data.push([]); // Add an empty row for spacing
        
        // Add headers
        const headers = ["Entité", "Nombre de bénéficiaires", "Valeurs en Dhs"];
        data.push(headers);
    
        // Add table rows
        entities.forEach(entity => {
            const totalBeneficiairesEntity = entity.totalBoys + entity.totalGirls;
            const rapportTrimestriel = rapportTrimestrielData.find(rd => rd.EntityId === entity.id && rd.TrimestreId === selectedTrimestreId);
    
            if (!rapportTrimestriel) {
                return;
            }
    
            const consommation = consommationData.find(c => c.RapportTrimestrielId === rapportTrimestriel.id);
            const valeursDhs = consommation ? consommation.totalParticipationBanqueAlimentaire : 0;
            data.push([entity.nom, totalBeneficiairesEntity, valeursDhs]);
        });
    
        // Add total row
        data.push(["Total", totalBeneficiaires, totalValeursDhs]);
    
        // Create a new Excel workbook
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet(data);
    
        // Set column widths
        worksheet['!cols'] = [
            { wch: 80 }, // Width of the 'Entité' column
            { wch: 25 }, // Width of the 'Nombre de bénéficiaires' column
            { wch: 25 }  // Width of the 'Valeurs en Dhs' column
        ];
    
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Distribution');
    
        // Write the workbook to a file
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    
        // Create a Blob from the Excel content
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    
        // Create a download link
        const url = URL.createObjectURL(blob);
    
        // Trigger download
        const a = document.createElement('a');
        a.href = url;
        a.download = `Distribution finale du ${selectedTrimestreNom}.xlsx`;
        a.click();
    
        // Clean up
        URL.revokeObjectURL(url);
    };
    
    
    return (
        <div>
            <Typography variant='h6'>
                Bilan des dotations trimestrielles
            </Typography>
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Année Scolaire</InputLabel>
                            <Select
                                value={selectedAnneeScolaireId}
                                onChange={(e) => setSelectedAnneeScolaireId(e.target.value)}
                            >
                                {anneeScolaires.map((anneeScolaire) => (
                                    <MenuItem key={anneeScolaire.id} value={anneeScolaire.id}>
                                        {anneeScolaire.nom}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth margin="normal" disabled={!selectedAnneeScolaireId}>
                            <InputLabel>Trimestre</InputLabel>
                            <Select
                                value={selectedTrimestreId}
                                onChange={(e) => setSelectedTrimestreId(e.target.value)}
                            >
                                {trimestres.map((trimestre) => (
                                    <MenuItem key={trimestre.id} value={trimestre.id}>
                                        {trimestre.nom}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>

            </div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Entité</TableCell>
                            <TableCell>Nombre de bénéficiaires</TableCell>
                            <TableCell>Valeurs en Dhs</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {entities.map(entity => {
                    // Find corresponding rapportTrimestriel
                    const rapportTrimestriel = rapportTrimestrielData.find(rd => rd.EntityId === entity.id && rd.TrimestreId === selectedTrimestreId);

                    if (!rapportTrimestriel) {
                        return null; // Skip if no corresponding rapportTrimestriel
                    }

                    // Filter all consommation products related to this rapportTrimestriel
                    const consommations = consommationData.filter(c => c.RapportTrimestrielId === rapportTrimestriel.id);

                    // Calculate total valeurs en DHS for all related consommations
                    const valeursDhs = consommations.reduce((sum, consommation) => sum + consommation.totalParticipationBanqueAlimentaire, 0);

                    // Calculate total beneficiaries for the entity
                    const totalBeneficiairesEntity = entity.totalBoys + entity.totalGirls;

                    return (
                        <TableRow key={entity.id}>
                            <TableCell>{entity.nom}</TableCell>
                            <TableCell>{totalBeneficiairesEntity}</TableCell>
                            <TableCell>{formatCurrency(valeursDhs)}</TableCell>
                        </TableRow>
                    );
                })}

                        {/* Add the total row */}
                        <TableRow>
                            <TableCell><strong>Total</strong></TableCell>
                            <TableCell><strong>{totalBeneficiaires}</strong></TableCell>
                            <TableCell><strong>{formatCurrency(totalValeursDhs)}</strong></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Button onClick={generateExcelData} variant='contained' color='success' sx={{ mt: 2 }}>Télécharger au format Excel</Button>
        </div>
    );
};

export default AfficherDistributionFinale;
