import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import {
    TextField,
    Button,
    IconButton,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Grid
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

const apiUrl = process.env.REACT_APP_API_URL;

const ConsommationProduct = ({ consommationProducts, setConsommationProducts, rapportTrimestrielId }) => {
    const [products, setProducts] = useState([]);
    const { t, i18n} = useTranslation();
    const switchLanguage = (language) => {
    i18n.changeLanguage(language);
    }

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axios.get(`${apiUrl}/product/all`);
                setProducts(response.data);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        fetchProducts();
    }, []);

    const handleAddProduct = () => {
        setConsommationProducts([
            ...consommationProducts,
            { productId: '', quantityReceived: '', totalRecu: '', totalParticipationBanqueAlimentaire: '' }
        ]);
    };

    const handleRemoveProduct = (index) => {
        const updatedProducts = [...consommationProducts];
        updatedProducts.splice(index, 1);
        setConsommationProducts(updatedProducts);
    };

    const handleProductChange = (index, field, value) => {
        console.log(`Updating product at index ${index}:`, { field, value });
        const updatedProducts = [...consommationProducts];
        updatedProducts[index][field] = value;
        setConsommationProducts(updatedProducts);
    };

    const getProductDetails = (productId) => {
        const product = products.find(p => p.id === productId);
        return product || {};
    };

    return (
        <form>
            {consommationProducts.map((product, index) => {
                const productDetails = getProductDetails(product.productId);

                return (
                    <Grid container spacing={2} key={index} alignItems="center">
                        <Grid item xs={12} md={3}>
                            <FormControl fullWidth>
                                <InputLabel id={`product-label-${index}`}>{t("product")}</InputLabel>
                                <Select
                                    labelId={`product-label-${index}`}
                                    value={product.productId || ''}
                                    onChange={(e) => handleProductChange(index, 'productId', e.target.value)}
                                    required
                                >
                                    <MenuItem value="">
                                        <em>Select a product</em>
                                    </MenuItem>
                                    {products.map((product) => (
                                        <MenuItem key={product.id} value={product.id}>
                                            {product.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                label={t("product description")}
                                value={productDetails.description || ''}
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                label={t("product price")}
                                value={productDetails.price +' MAD'|| ''}
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                label={t("measurement unit")}
                                value={productDetails.measurementUnit || ''}
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <TextField
                                label={t("quantity received")}
                                type="number"
                                value={product.quantityReceived}
                                onChange={(e) => handleProductChange(index, 'quantityReceived', e.target.value)}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                label={t("total amount received")}
                                type="number"
                                value={product.totalRecu}
                                onChange={(e) => handleProductChange(index, 'totalRecu', e.target.value)}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                label={t("total amount received from the food bank")}
                                type="number"
                                value={product.totalParticipationBanqueAlimentaire}
                                onChange={(e) => handleProductChange(index, 'totalParticipationBanqueAlimentaire', e.target.value)}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={1}>
                            <IconButton onClick={() => handleRemoveProduct(index)} color="secondary">
                                <RemoveCircleIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                );
            })}
            <Button
                variant="contained"
                color="primary"
                onClick={handleAddProduct}
                startIcon={<AddCircleIcon />}
                sx={{ mt: 2 }}
            >
                {t("add product")}
            </Button>
        </form>
    );
};

export default ConsommationProduct;
