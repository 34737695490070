import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Grid, TextField, Typography, Container, Button } from "@mui/material";

const apiUrl = process.env.REACT_APP_API_URL;

export default function EditUser() {
    const { id } = useParams();
    const [user, setUser] = useState(null);
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        nom: "",
        prenom: "",
        telephone: "",
        telephoneSecondaire: "",
        password: ""
    });

    useEffect(() => {
      // Fetch user data by Id
      const fetchUserById = async () => {
        try {
            const response = await axios.get(`${apiUrl}/user/${id}`);
            setUser(response.data);
            // Initialize form data with fetched user data
            setFormData({
                nom: response.data.nom,
                prenom: response.data.prenom,
                telephone: response.data.telephone,
                telephoneSecondaire: response.data.telephoneSecondaire,
                password: ""
            });
            console.log(formData);
        } catch (error) {
            console.error("Error fetching user by id:", error);
        }
      };

      fetchUserById();
    }, [id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const updatedData = {
            nom: formData.nom,
            prenom: formData.prenom,
            telephone: formData.telephone,
            telephoneSecondaire: formData.telephoneSecondaire,
        };

        // Include password only if it's provided
        if (formData.password) {
            updatedData.password = formData.password;
        }

        try {
            const response = await axios.put(`${apiUrl}/user/${id}`, updatedData);
            console.log("User updated successfully:", response.data);
            navigate(`/user/responsable/all`);
        } catch (error) {
            console.error("Error updating user:", error);
        }
    };

    const handleInputChange = async (e) => {
        const { name, value } = e.target;
        setFormData((prevData) =>({
            ...prevData,
            [name]: value
        }));
    }

    return (
        <Container>
            <Typography variant="h4" gutterBottom>Modifier le responsable</Typography>
        <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Nom"
                        name="nom"
                        value={formData.nom}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Prenom"
                        name="prenom"
                        value={formData.prenom}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Téléphone"
                        name="telephone"
                        value={formData.telephone}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Téléphone Secondaire"
                        name="telephoneSecondaire"
                        value={formData.telephoneSecondaire}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Nouveau mot de passe"
                        name="password"
                        type="password"
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                    />
                </Grid>
                {/* Submission button */}
                <Grid item xs={12}>
                    <Button type="submit" variant="contained" color="success">
                        Sauvegarder les changements
                    </Button>
                </Grid>
            </Grid>
        </form>
        </Container>
    );
}