import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Button, IconButton, Typography } from '@mui/material';
import axios from 'axios';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';

const apiUrl = process.env.REACT_APP_API_URL;

const Associations = () => {
    const [data, setData] = useState([]); // State to hold the fetched data
    const [searchTerm, setSearchTerm] = useState('');
    const [regions, setRegions] = useState([]); // State to hold the fetched region data
    const [responsables, setResponsables] = useState([]); // State to hold the fetched responsable data
    const [prefectures, setPrefectures] = useState([]);
    const [provinces, setProvinces] = useState([]);
    const [locations, setLocations] = useState({}); // State to hold location data


    // Fetching the Data
    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch Association data
                const associationResponse = await axios.get(`${apiUrl}/entity/association/all`);
                setData(associationResponse.data); // Set the fetched data to the state

                // Fetch region data
                const regionsResponse = await axios.get(`${apiUrl}/region/all`);
                setRegions(regionsResponse.data); // Set region data to the state

                // Fetch Responsable data
                const responsablesResponse = await axios.get(`${apiUrl}/user/all`);
                setResponsables(responsablesResponse.data); // Set responsable data to the state

                // Fetch prefecture data
                const prefecturesResponse = await axios.get(`${apiUrl}/prefecture/all`);
                setPrefectures(prefecturesResponse.data); // Set prefecture data to the state

                // Fetch province data
                const provincesResponse = await axios.get(`${apiUrl}/province/all`);
                setProvinces(provincesResponse.data); // Set province data to the state
            } catch (error) {
                console.error('Error fetching data:', error);
        }
    };

        fetchData(); // Call the fetchData function when the component mounts
    }, []);

    // Function to get location name by ID
    const getLocationName = (locationId, locationType) => {
        if (locationType === 'Province') {
            const province = provinces.find(province => province.id === locationId);
            return province ? province.nom : '';
        } else if (locationType === 'Prefecture') {
            const prefecture = prefectures.find(prefecture => prefecture.id === locationId);
            return prefecture ? prefecture.nom : '';
        }
        return '';
    };

    // Function to get region name
    const getRegionName = (locationId, locationType) => {
    if (locationType === 'Province') {
        const province = provinces.find(province => province.id === locationId);
        if (province) {
            const regionId = province.RegionId;
            const region = regions.find(region => region.id === regionId);
            return region ? region.nom : '';
        }
    } else if (locationType === 'Prefecture') {
        const prefecture = prefectures.find(prefecture => prefecture.id === locationId);
        if (prefecture) {
            const regionId = prefecture.RegionId;
            const region = regions.find(region => region.id === regionId);
            return region ? region.nom : '';
        }
    }
    return '';
    };


    // Function to fetch Responsable name by Id
    const getResponsableName = (responsableId) => {
        const responsable = responsables.find(responsable => responsable.id === responsableId);
        return responsable ? `${responsable.nom} ${responsable.prenom}` : ''; // Return Responsable name if found, otherwise empty string
    };

    // Filter data based on search term
    const filteredData = data.filter(item =>
        item.nom.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div>
            <Typography variant='h6'>Liste des Associations</Typography>
            <TextField
                label="Association ..."
                variant="outlined"
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{ marginBottom: '20px', marginTop: '20px' }}
            />
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Association</TableCell>
                            <TableCell>Responsable</TableCell>
                            <TableCell>Telephone</TableCell>
                            <TableCell>Telephone Fixe</TableCell>
                            <TableCell>Nombre de Filles</TableCell>
                            <TableCell>Nombre de Garçons</TableCell>
                            <TableCell>Préfecture/Province</TableCell>
                            <TableCell>Région</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredData.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell>{row.nom}</TableCell>
                                <TableCell>{getResponsableName(row.UserId)}</TableCell>
                                <TableCell>{row.telephone}</TableCell>
                                <TableCell>{row.telephoneFixe}</TableCell>
                                <TableCell>{row.totalGirls}</TableCell>
                                <TableCell>{row.totalBoys}</TableCell>
                                <TableCell>{getLocationName(row.locationId, row.locationType)}</TableCell>
                                <TableCell>{getRegionName(row.locationId, row.locationType)}</TableCell>
                                <TableCell><IconButton component={Link} to={`/entity/${row.id}`}><RemoveRedEyeOutlinedIcon/></IconButton></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default Associations;
