import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Grid, TextField, Typography, Button, Snackbar, Alert } from '@mui/material';

const apiUrl = process.env.REACT_APP_API_URL;

const EditTrimestre = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [trimestre, setTrimestre] = useState({});
    const [formData, setFormData] = useState({
        nom: "",
        dateDebut: "", 
        dateFin: "",
    });

    useEffect(() => {
        const fetchTrimestre = async () => {
            try {
                const response = await axios.get(`${apiUrl}/trimestre/${id}`);
                setTrimestre(response.data);
                setFormData({
                    nom: response.data.nom,
                    dateDebut: response.data.dateDebut,
                    dateFin: response.data.dateFin,
                });
            } catch (error) {
                console.error('Error fetching trimestre:', error);
            }
        };

        fetchTrimestre();
    }, [id]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const formattedData = {
                nom: formData.nom,
                dateDebut: new Date(formData.dateDebut).toISOString(), // Convert to ISO string
                dateFin: new Date(formData.dateFin).toISOString() // Convert to ISO string
            };
    
            await axios.put(`${apiUrl}/trimestre/${id}/edit`, formattedData);
            // Redirect or show success message
            setSnackbarOpen(true);
        } catch (error) {
            console.error('Error updating trimestre:', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
        navigate(`/trimestre/${id}`);
    };

    return (
        <Container>
            <Typography variant='h4'>Modifier trimestre</Typography>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Nom"
                            name="nom"
                            value={formData.nom}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Date de début"
                            type="date"
                            name="dateDebut"
                            value={formData.dateDebut}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Date de fin"
                            type="date"
                            name="dateFin"
                            value={formData.dateFin}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button type="submit" variant="contained" color="warning">
                            Enregistrer les modifications
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={1500}
                onClose={handleCloseSnackbar}
            >
                <Alert onClose={handleCloseSnackbar} severity="success" variant='filled'>
                    Trimestre mis à jour avec succès.
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default EditTrimestre;
